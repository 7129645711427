import React from "react";
import { withRouter } from "react-router-dom";
import Div100vh from "react-div-100vh";

import firebase from "../../firebase";
import UnitPickerMapController from "../components/unitpickermap/UnitPickerMapController";

class UnitMapPickerPage extends React.Component {
  constructor(props) {
    super(props);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();

    this.auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.props.history.push("/login");
      } else {
        const userRef = firebase.firestore().doc(`users/${user.uid}`);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          return;
        }
        this.props.history.push("/railserve");
      }
    });
  }

  render() {
    return (
      <Div100vh>
        <UnitPickerMapController />
      </Div100vh>
    );
  }
}
export default withRouter(UnitMapPickerPage);
