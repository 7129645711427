import React from "react";

import {
  getAllowedSitesData,
  getUnitData,
  updateNotificationSetting,
} from "../../helpers/firebaseHelper";
import fetchFromAPI from "../../helpers/requestHelper";
import RequestTypes from "../../enums/RequestTypes";
import firebase from "../../../firebase";
import { UnitTypes } from "../../enums/UnitTypes";
import {
  VIEW_LEVEL_USA,
  LAST_VIEW_LEVEL_MAP_STORAGE,
} from "../../helpers/stringProvider";

export default function withUnitMap(MapControllerComponent) {
  class WithUnitMap extends React.Component {
    constructor(props) {
      super(props);

      this.getUnits = this.getUnits.bind(this);
      this.setupMap = this.setupMap.bind(this);
      this.updateLastShadowDerail = this.updateLastShadowDerail.bind(this);
      this.setViewLevel = this.setViewLevel.bind(this);
      this.updateUnitsFoundNickname = this.updateUnitsFoundNickname.bind(this);
      this.updateUnitData = this.updateUnitData.bind(this);
      this.onDerailNotificationSettingChange =
        this.onDerailNotificationSettingChange.bind(this);

      this.removeUnitFromUnitsFound = this.removeUnitFromUnitsFound.bind(this);

      this.auth = firebase.auth();
      this.firestore = firebase.firestore();

      this.auth.onAuthStateChanged((user) => {
        if (!user) {
          // TODO: Add router here
          // this.userRouteLogout();
        } else {
          this.getUnits();
          this.setupMap();
        }
      });
    }

    state = {
      unitsFound: {},
      allowedSitesData: [],
      finishedGettingUnits: false,
      toDisplayMap: false,
      viewLevel:
        localStorage.getItem(LAST_VIEW_LEVEL_MAP_STORAGE) ?? VIEW_LEVEL_USA,
      viewLevelUpdater: false,
    };

    removeUnitFromUnitsFound(unitId) {
      let derailUnits = this.state.unitsFound[UnitTypes.DERAIL];
      let derailUnit = derailUnits[unitId];
      derailUnit.coordinates = undefined;

      let newUnitsFound = this.state.unitsFound;
      newUnitsFound[UnitTypes.DERAIL] = derailUnits;
      this.setState({ unitsFound: newUnitsFound });
    }

    updateUnitsFoundNickname(unitId, newNickname) {
      let derailUnits = this.state.unitsFound[UnitTypes.DERAIL];
      let derailUnit = derailUnits[unitId];
      derailUnit.safeyardNickname = newNickname;
      derailUnits[unitId] = derailUnit;
      let newUnitsFound = this.state.unitsFound;
      newUnitsFound[UnitTypes.DERAIL] = derailUnits;
      this.setState({ unitsFound: newUnitsFound });
    }

    updateLastShadowDerail(unitId, derailErrorsShadow) {
      if (!this.state.finishedGettingUnits) {
        return;
      }
      let currUnitData = this.state.unitsFound[UnitTypes.DERAIL][unitId];
      if (currUnitData === undefined) {
        return;
      }
      if (currUnitData.shadow === undefined) {
        currUnitData.shadow = derailErrorsShadow;
      } else {
        Object.entries(derailErrorsShadow).forEach(([key, value]) => {
          currUnitData.shadow[key] = value;
        });
      }
      let currDerailUnitsFound = this.state.unitsFound[UnitTypes.DERAIL];
      currDerailUnitsFound[unitId] = currUnitData;

      let currUnitsFound = this.state.unitsFound;
      currUnitsFound[UnitTypes.DERAIL] = currDerailUnitsFound;
      this.setState({ unitsFound: currUnitsFound });
    }

    async setupMap() {
      await getAllowedSitesData(this.auth.currentUser, {
        onSiteData: async (site, siteData) => {
          const newAllowedSitesData = {
            ...this.state.allowedSitesData,
            [site]: siteData,
          };

          // Order site data
          const orderedSitesData = Object.keys(newAllowedSitesData)
            .sort()
            .reduce((obj, key) => {
              obj[key] = newAllowedSitesData[key];
              return obj;
            }, {});

          if (!(this.state.viewLevel in orderedSitesData)) {
            this.setState({ viewLevel: VIEW_LEVEL_USA });
          }
          this.setState({
            allowedSitesData: orderedSitesData,
            toDisplayMap: true,
          });
        },
        firstSite: this.state.viewLevel,
      });
    }

    async getUnits() {
      fetchFromAPI(RequestTypes.UNITS, {}).then(async (result) => {
        if (result.success) {
          const dataArr = Object.values(result.data);
          let newUnitData = {};
          newUnitData[UnitTypes.DERAIL] = {};
          for (const unit of dataArr) {
            if (unit?.modelId === "z5Ql7LA0vmoeJFApLYrUfg") {
              const unitMetadata = await getUnitData(unit.id);
              newUnitData[UnitTypes.DERAIL][unit.id] = {
                ...unit,
                ...unitMetadata,
              };
              this.setState({ unitsFound: newUnitData });
            }
          }
          this.setState({ finishedGettingUnits: true });
        } else {
          if (result.message) {
            console.log(result.message);
          }
        }
      });
    }

    onDerailNotificationSettingChange(
      unitId,
      newValue,
      {
        isSiteLeader = false,
        isSeniorSiteLeader = false,
        isRegionalLeader = false,
        otherUids = [],
      }
    ) {
      updateNotificationSetting(unitId, newValue, {
        isSiteLeader: isSiteLeader,
        isSeniorSiteLeader: isSeniorSiteLeader,
        isRegionalLeader: isRegionalLeader,
        otherUids: otherUids,
      });

      let unitData = this.state.unitsFound[UnitTypes.DERAIL][unitId];
      let uidList = [];
      if (isSiteLeader) {
        unitData.isSiteLeaderNotificationsEnabled = newValue;
        if (this.state.allowedSitesData[unitData.site].siteLeaderUid) {
          uidList.push(
            this.state.allowedSitesData[unitData.site].siteLeaderUid
          );
        }
      }
      if (isSeniorSiteLeader) {
        unitData.isSeniorSiteLeaderNotificationsEnabled = newValue;
        if (this.state.allowedSitesData[unitData.site].seniorSiteLeaderUid) {
          uidList.push(
            this.state.allowedSitesData[unitData.site].seniorSiteLeaderUid
          );
        }
      }
      if (isRegionalLeader) {
        unitData.isRegionalLeaderNotificationsEnabled = newValue;
        if (this.state.allowedSitesData[unitData.site].regionalLeaderUid) {
          uidList.push(
            this.state.allowedSitesData[unitData.site].regionalLeaderUid
          );
        }
      }
      uidList = uidList.concat(otherUids);
      if (newValue) {
        unitData.addedNotificationUids = [
          ...unitData.addedNotificationUids,
          ...otherUids,
        ];
        fetchFromAPI(RequestTypes.ADD_UIDS_TO_DEVICE_NOTIFICATIONS, {
          parameters: { id: unitId },
          queryParameters: { uidList: uidList },
        });
      } else {
        fetchFromAPI(RequestTypes.REMOVE_UIDS_FROM_DEVICE_NOTIFICATIONS, {
          parameters: { id: unitId },
          queryParameters: { uidList: uidList },
        });
        unitData.addedNotificationUids = unitData.addedNotificationUids.filter(
          (id) => !otherUids.includes(id)
        );
      }
      let unitsFound = this.state.unitsFound;
      unitsFound[UnitTypes.DERAIL][unitId] = unitData;

      this.setState({ unitsFound: unitsFound });
    }

    updateUnitData(unitType, unitId, newData) {
      let unitData = this.state.unitsFound[unitType][unitId];
      let newUnitTypesFound = this.state.unitsFound[unitType];
      let newCoords = unitData?.coordinates ?? [newData.lng, newData.lat];
      if (unitData) {
        Object.keys(newData).forEach((key) => {
          if (key === "lat") {
            newCoords[1] = newData[key];
            return;
          }
          if (key === "lng") {
            newCoords[0] = newData[key];
            return;
          }
          unitData[key] = newData[key];
        });
      } else {
        unitData = newData;
      }
      unitData.coordinates = newCoords;
      newUnitTypesFound[unitId] = unitData;
      let newUnitsFound = this.state.unitsFound;
      newUnitsFound[unitType] = newUnitTypesFound;
      this.setState({ unitsFound: newUnitsFound });
    }

    setViewLevel(viewLevel) {
      localStorage.setItem(LAST_VIEW_LEVEL_MAP_STORAGE, viewLevel);
      this.setState({
        viewLevel: viewLevel,
        viewLevelUpdater: !this.state.viewLevelUpdater,
        isBottomSlidingOpen: false,
        isReturnToSiteSnackbarVisible: false,
      });
    }

    render() {
      return (
        <MapControllerComponent
          unitsFound={this.state.unitsFound}
          allowedSitesData={this.state.allowedSitesData}
          finishedGettingUnits={this.state.finishedGettingUnits}
          toDisplayMap={this.state.toDisplayMap}
          viewLevel={this.state.viewLevel}
          viewLevelUpdater={this.state.viewLevelUpdater}
          updateUnitData={this.updateUnitData}
          removeUnitFromUnitsFound={this.removeUnitFromUnitsFound}
          getUnits={this.getUnits}
          updateLastShadowDerail={this.updateLastShadowDerail}
          setViewLevel={this.setViewLevel}
          updateUnitsFoundNickname={this.updateUnitsFoundNickname}
          onDerailNotificationSettingChange={
            this.onDerailNotificationSettingChange
          }
        />
      );
    }
  }
  WithUnitMap.displayName = `WithUnitMap${getDisplayName(
    MapControllerComponent
  )}`;
  return WithUnitMap;
}

function getDisplayName(MapControllerComponent) {
  return (
    MapControllerComponent.displayName ||
    MapControllerComponent.name ||
    "Component"
  );
}
