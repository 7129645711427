import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import BaseButton from "../common/BaseButton";

export default function UploadJsonComponent(props) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({ accept: "application/json", multiple: false });

  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [invalidFileMessage, setInvalidFileMessage] = useState("Invalid File");

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const submitData = async () => {
    setIsSubmitSuccess(false);
    if (acceptedFiles && acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async (e) => {
        let metadata;
        try {
          metadata = JSON.parse(e.target.result);
          setIsInvalidFile(false);
        } catch (err) {
          console.log(err);
          setInvalidFileMessage("Invalid JSON");
          setIsInvalidFile(true);
          return;
        }
        const resp = await props.handleDataSubmit(metadata);
        if (resp.success) {
          setIsInvalidFile(false);
          setIsSubmitSuccess(true);
        } else {
          setInvalidFileMessage(resp.invalidFileMessage);
          setIsInvalidFile(true);
        }
      };
      reader.readAsText(acceptedFiles[0]);
    } else {
      setInvalidFileMessage("Invalid file");
      setIsInvalidFile(true);
    }
  };

  return (
    <div style={{ display: "flex", width: "90%", justifyContent: "center" }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          style={{
            display: "flex",
            padding: ".5em",
            width: "12vw",
            minWidth: "8em",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderStyle: "dotted",
            borderColor: "white",
          }}
        >
          <h3 style={{ textAlign: "center", padding: 0, margin: 0 }}>
            Upload {props.title}
          </h3>
          <em style={{ padding: 0, textAlign: "center" }}>
            (Only *.json will be accepted)
          </em>
          {acceptedFiles && acceptedFiles[0] && (
            <p style={{ margin: 0, padding: ".5em 0", textAlign: "center" }}>
              {acceptedFiles[0].name}
            </p>
          )}
          {((fileRejections && fileRejections[0]) || isInvalidFile) && (
            <p
              style={{
                margin: 0,
                padding: ".5em 0",
                textAlign: "center",
                color: "red",
              }}
            >
              {invalidFileMessage}
            </p>
          )}
          {isSubmitSuccess && (
            <p
              style={{
                margin: 0,
                padding: ".5em 0",
                textAlign: "center",
                color: "green",
              }}
            >
              Success!
            </p>
          )}
        </div>
      </div>
      <div style={{ width: "2em" }} />
      <BaseButton
        style={{ width: "12vw", minWidth: "8em" }}
        onClick={submitData}
      >
        Submit {props.title}
      </BaseButton>
    </div>
  );
}
