import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

const NotificationSwitch = ({
  canUserChangeNotifications,
  isEnabled,
  onChange,
  text,
}) => (
  <FormControlLabel
    control={
      <Switch
        disabled={!canUserChangeNotifications}
        checked={isEnabled}
        onChange={onChange}
        color="primary"
      />
    }
    label={
      <p style={{ margin: "0", padding: "0" }}>
        {text}:{" "}
        <span style={{ color: isEnabled ? "green" : "red" }}>
          {isEnabled ? "On" : "Off"}
        </span>
      </p>
    }
  />
);

export default NotificationSwitch;
