import React from "react";
import { Source, Layer } from "react-mapbox-gl";

const MapSitesOverlay = ({ sitesData }) => {
  return (
    <>
      <Source
        id="siteCentersSource"
        key="siteCentersSource"
        geoJsonSource={{
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: Object.values(sitesData).map((siteData) => ({
              type: "Feature",
              geometry: { type: "Point", coordinates: siteData.center },
              properties: { siteName: siteData.site, viewLevel: siteData.site },
            })),
          },
        }}
      />
      <Layer
        id="siteCentersLayer"
        type="symbol"
        maxZoom={12.1}
        layout={{
          "icon-image": "location",
          "icon-size": 0.04,
          "icon-allow-overlap": true,
        }}
        sourceId="siteCentersSource"
      />
    </>
  );
};

export default MapSitesOverlay;
