import React from "react";
import { Layer } from "react-mapbox-gl";
import { DerailStatus } from "../enums/UnitStatuses";
import { UnitTypes } from "../enums/UnitTypes";

const unitTypeAndStatusToColor = (unitType, unitStatus) => {
  if (unitType === UnitTypes.DERAIL) {
    if (unitStatus === DerailStatus.DERAIL_OFF) {
      return "green";
    }
    if (unitStatus === DerailStatus.DISCONNECTED) {
      return "orange";
    }
    if (unitStatus === DerailStatus.DERAIL_ON) {
      return "#EC0015";
    }
  }
  return "grey";
};

export function createLayerComponent(
  unitType,
  unitStatus,
  sourceTitle,
  layerTitle,
  filterStatus
) {
  let toShow = true;
  if (filterStatus) {
    toShow = filterStatus[unitType][unitStatus];
  }
  if (unitType === UnitTypes.DERAIL) {
    return (
      toShow && (
        <Layer
          id={layerTitle}
          key={layerTitle}
          type="circle"
          minZoom={12}
          sourceId={sourceTitle}
          paint={{
            "circle-radius": 8,
            "circle-color": unitTypeAndStatusToColor(unitType, unitStatus),
          }}
        />
      )
    );
  } else {
    return (
      toShow && (
        <Layer
          type="line"
          layout={{
            "line-cap": "round",
          }}
          key={layerTitle}
          sourceId={sourceTitle}
          paint={{
            "line-width": 16,
            "line-pattern": "arrow",
          }}
        />
      )
    );
  }
}
