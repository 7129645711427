export function getThemeColor() {
  return "#112337";
}
export function getAccentColor() {
  return "#FFF";
}
export function getPressedAccentColor() {
  return "#FFF";
}
export function getUnpressedAccentColor() {
  return "#112337";
}
