import React from "react";
import { io } from "socket.io-client";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";

import { UnitTypes } from "../../enums/UnitTypes";
import firebase from "../../../firebase";
import UnitMapLeftSidebar from "../common/UnitMapLeftSidebar";
import SlidingMenu from "../common/SlidingMenu";
import SelectableButton from "../common/SelectableButton";
import withUnitMap from "../higher_order/withUnitMap";
import UnitPickerMap from "./UnitPickerMap";
import { unitTypeToStatuses } from "../../enums/UnitStatuses";
import { formatSite, formatThingName } from "../../helpers/stringFormatter";
import {
  createNewDevice,
  deleteDevice,
  updateNewDevice,
  removeDevice,
} from "../../helpers/firebaseHelper";
import fetchFromAPI from "../../helpers/requestHelper";
import RequestTypes from "../../enums/RequestTypes";
import CloseableSnackbar from "../common/CloseableSnackbar";
import {
  VIEW_LEVEL_USA,
  LAST_LAT_PICKER_STORAGE,
  LAST_LNG_PICKER_STORAGE,
  LAST_ZOOM_PICKER_STORAGE,
  LAST_VIEW_LEVEL_MAP_STORAGE,
} from "../../helpers/stringProvider";

class UnitPickerMapController extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewDeviceClose = this.handleNewDeviceClose.bind(this);
    this.setIsNewDerailDialogOpen = this.setIsNewDerailDialogOpen.bind(this);
    this.createNewDerail = this.createNewDerail.bind(this);

    this.handleRemoveDeviceClose = this.handleRemoveDeviceClose.bind(this);
    this.setIsRemoveUnitOpen = this.setIsRemoveUnitOpen.bind(this);
    this.removeDerail = this.removeDerail.bind(this);

    this.setViewLevel = this.setViewLevel.bind(this);
    this.updateMapLocalStorage = this.updateMapLocalStorage.bind(this);
    this.setClickedCoordinates = this.setClickedCoordinates.bind(this);
    this.setClickedUnitIdType = this.setClickedUnitIdType.bind(this);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();

    this.socket = io();

    this.socket.on("shadowChange", (newShadowData) => {
      if (newShadowData.id) {
        this.props.updateLastShadowDerail(
          newShadowData.id,
          newShadowData.shadow
        );
      }
    });

    this.socket.on("error", (e) => {
      console.log("Error in socket");
      console.log(e);
    });

    // All Units
    this.filterStatus = {};
    Object.values(UnitTypes).forEach((unitType) => {
      this.filterStatus[unitType] = {};
      Object.values(unitTypeToStatuses(unitType)).forEach((status) => {
        this.filterStatus[unitType][status] = true;
      });
    });

    this.state = {
      requestReportOpen: false,
      isBottomSlidingOpen: true,

      clickedCoordinates: null,
      newDeviceThingName: "",
      newDeviceSite: localStorage.getItem(LAST_VIEW_LEVEL_MAP_STORAGE) ?? "",
      newDeviceNickname: "",
      isNewDerailDialogOpen: false,

      isRemoveUnitOpen: false,
    };
  }

  setIsRemoveUnitOpen(isOpen) {
    this.setState({ isRemoveUnitOpen: isOpen });
  }

  handleRemoveDeviceClose() {
    this.setState({ isRemoveUnitOpen: false });
  }

  setIsNewDerailDialogOpen(isOpen) {
    this.setState({ isNewDerailDialogOpen: isOpen });
  }

  setClickedCoordinates(newCoordinates) {
    this.setState({ clickedCoordinates: newCoordinates });
  }

  async createNewDerail() {
    // Check device with given thing_name in MiM.
    // If it exists, get its ID from MiM.
    this.setState({ isCreatingNewDevice: true });
    const thingName = this.state.newDeviceThingName.trim();
    const getDeviceIdResp = await fetchFromAPI(RequestTypes.GET_DEVICE_ID, {
      parameters: { thingName: thingName },
    });
    if (!getDeviceIdResp.success) {
      this.setState({
        isCreatingNewDevice: false,
        isGettingDeviceIdFromThingNameError: true,
        isNewDerailDialogOpen: false,
      });
      return;
    }
    const unitId = getDeviceIdResp.deviceId;
    const newUnitData = {
      lat: this.state.clickedCoordinates[1],
      lng: this.state.clickedCoordinates[0],
      safeyardNickname:
        this.state.newDeviceNickname.trim() === ""
          ? formatThingName(thingName)
          : this.state.newDeviceNickname.trim(),
      site: this.state.newDeviceSite,
      thingName: thingName,
      id: unitId,
      toRefresh: true,
      addedNotificationUids: [],
      isSiteLeaderNotificationsEnabled: false,
      isSeniorSiteLeaderNotificationsEnabled: false,
      isRegionalLeaderNotificationsEnabled: false,
    };
    const success = await createNewDevice(unitId, newUnitData);
    if (success !== 0) {
      if (success == 1) {
        // Device already exists in firebase
        this.setState({ displayConfirmExistingDeviceOverwrite: true });
        return;
      } else {
        // Other error
        this.setState({
          isCreatingNewDevice: false,
          isCreatingNewDeviceError: true,
          isNewDerailDialogOpen: false,
        });
        return;
      }
    }
    const resp = await fetchFromAPI(RequestTypes.PULL_NEW_DEVICE, {
      parameters: { id: unitId },
    });
    if (resp.success) {
      this.setState({
        newDeviceNickname: "",
        newDeviceThingName: "",
        isCreatingNewDevice: false,
        isCreatingNewDeviceError: false,
        isNewDerailDialogOpen: false,
        createNewDeviceSuccess: true,
      });
      this.props.updateUnitData(UnitTypes.DERAIL, unitId, newUnitData);
    } else {
      await deleteDevice(unitId);
      this.setState({
        isCreatingNewDevice: false,
        isCreatingNewDeviceError: true,
        isNewDerailDialogOpen: false,
      });
    }
  }

  async overwriteNewDevice() {
    this.setState({ displayConfirmExistingDeviceOverwrite: false });

    const thingName = this.state.newDeviceThingName.trim();
    const getDeviceIdResp = await fetchFromAPI(RequestTypes.GET_DEVICE_ID, {
      parameters: { thingName: thingName },
    });
    if (!getDeviceIdResp.success) {
      this.setState({
        isCreatingNewDevice: false,
        isGettingDeviceIdFromThingNameError: true,
        isNewDerailDialogOpen: false,
      });
      return;
    }
    const unitId = getDeviceIdResp.deviceId;
    const newUnitData = {
      lat: this.state.clickedCoordinates[1],
      lng: this.state.clickedCoordinates[0],
      safeyardNickname:
        this.state.newDeviceNickname.trim() === ""
          ? formatThingName(thingName)
          : this.state.newDeviceNickname.trim(),
      site: this.state.newDeviceSite,
      thingName: thingName,
      id: unitId,
      addedNotificationUids: [],
      isSiteLeaderNotificationsEnabled: false,
      isSeniorSiteLeaderNotificationsEnabled: false,
      isRegionalLeaderNotificationsEnabled: false,
      toRefresh: true,
    };
    const success = await updateNewDevice(unitId, newUnitData);
    if (success !== 0) {
      this.setState({
        isCreatingNewDevice: false,
        isCreatingNewDeviceError: true,
        isNewDerailDialogOpen: false,
      });
      return;
    }
    const resp = await fetchFromAPI(RequestTypes.REFRESH_BACKEND, {});
    if (resp.success) {
      this.setState({
        newDeviceNickname: "",
        newDeviceThingName: "",
        isCreatingNewDevice: false,
        isCreatingNewDeviceError: false,
        isNewDerailDialogOpen: false,
        createNewDeviceSuccess: true,
      });
      this.props.updateUnitData(UnitTypes.DERAIL, unitId, newUnitData);
    } else {
      this.setState({
        isCreatingNewDevice: false,
        isCreatingNewDeviceError: true,
        isNewDerailDialogOpen: false,
      });
    }
  }

  async removeDerail() {
    const success = await removeDevice(this.state.clickedUnitId);
    if (success) {
      await fetchFromAPI(RequestTypes.REFRESH_BACKEND, {});
      this.props.removeUnitFromUnitsFound(this.state.clickedUnitId);
    }
    this.handleRemoveDeviceClose();
  }

  // Could be moved to withUnitMap
  setClickedUnitIdType(unitId, unitType) {
    this.setState({ clickedUnitId: unitId, clickedUnitType: unitType });
  }

  handleNewDeviceClose() {
    this.setState({ isCreatingNewDevice: false, isNewDerailDialogOpen: false });
  }

  setViewLevel(viewLevel) {
    this.props.setViewLevel(viewLevel);
    if (viewLevel !== VIEW_LEVEL_USA) {
      const siteData = this.props.allowedSitesData[viewLevel];
      const newZoom = isMobile ? siteData.mobileZoom : siteData.webZoom;
      this.updateMapLocalStorage({
        zoom: newZoom,
        lat: siteData.center[1],
        lng: siteData.center[0],
        site: viewLevel,
      });
      this.setState({ newDeviceSite: viewLevel, clickedCoordinates: null });
    }
  }

  updateMapLocalStorage({ lat, lng, zoom, site }) {
    if (zoom) localStorage.setItem(LAST_ZOOM_PICKER_STORAGE, zoom);
    if (lat) localStorage.setItem(LAST_LAT_PICKER_STORAGE, lat);
    if (lng) localStorage.setItem(LAST_LNG_PICKER_STORAGE, lng);
    if (site) localStorage.setItem(LAST_VIEW_LEVEL_MAP_STORAGE, site);
  }

  render() {
    let newDerailDialogActions, newDerailDialogContent;
    if (this.state.displayConfirmExistingDeviceOverwrite) {
      newDerailDialogActions = (
        <React.Fragment>
          <Button onClick={this.handleNewDeviceClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.overwriteNewDevice();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </React.Fragment>
      );
      newDerailDialogContent = (
        <React.Fragment>
          <DialogContentText>
            A device with this thing name is already associated to an existing
            record. Would you like to overwrite this data?
          </DialogContentText>
        </React.Fragment>
      );
    } else if (this.state.isCreatingNewDevice) {
      newDerailDialogActions = <CircularProgress />;
      newDerailDialogContent = <DialogContentText>...</DialogContentText>;
    } else {
      newDerailDialogActions = (
        <React.Fragment>
          <Button onClick={this.handleNewDeviceClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.createNewDerail();
            }}
            color="primary"
          >
            Submit
          </Button>
        </React.Fragment>
      );
      newDerailDialogContent = (
        <React.Fragment>
          <DialogContentText>
            First choose the thing name of the device you want to associate with
            this new derail. Then assign it a site and nickname. A device with
            no nickname will be given its shortened thing name instead.
          </DialogContentText>
          <div style={{ display: "flex" }}>
            <div style={{ width: "8vw" }}>
              <TextField
                label="*MiM Thing Name*"
                value={this.state.newDeviceThingName}
                onChange={(e) =>
                  this.setState({ newDeviceThingName: e.target.value })
                }
              />
            </div>
            <div style={{ marginLeft: "1vw" }}>
              <InputLabel id="site-select-label">Site</InputLabel>
              <Select
                style={{ width: "8vw" }}
                labelId="site-select-label"
                value={this.state.newDeviceSite}
                onChange={(e) =>
                  this.setState({ newDeviceSite: e.target.value })
                }
              >
                {Object.keys(this.props.allowedSitesData).map((site) => {
                  return (
                    <MenuItem key={site} value={site}>
                      {formatSite(site)}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginLeft: "1vw" }}>
              <div style={{ width: "8vw" }}>
                <TextField
                  label="Nickname"
                  value={this.state.newDeviceNickname}
                  onChange={(e) =>
                    this.setState({ newDeviceNickname: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.props.toDisplayMap && (
          <React.Fragment>
            <UnitPickerMap
              viewLevel={this.props.viewLevel}
              setViewLevel={this.setViewLevel}
              viewLevelUpdater={this.props.viewLevelUpdater}
              width="100%"
              height="100%"
              unitsFound={this.props.unitsFound}
              allowedSitesData={this.props.allowedSitesData}
              filterStatus={this.filterStatus}
              updateMapLocalStorage={this.updateMapLocalStorage}
              setClickedCoordinates={this.setClickedCoordinates}
              clickedCoordinates={this.state.clickedCoordinates}
              clickedUnitId={this.state.clickedUnitId}
              clickedUnitType={this.state.clickedUnitType}
              setClickedUnitIdType={this.setClickedUnitIdType}
              setIsNewDerailDialogOpen={this.setIsNewDerailDialogOpen}
              setIsRemoveUnitOpen={this.setIsRemoveUnitOpen}
            />
            <Dialog
              open={this.state.isNewDerailDialogOpen}
              onClose={this.handleNewDeviceClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">New Derail</DialogTitle>
              <DialogContent>{newDerailDialogContent}</DialogContent>
              <DialogActions>{newDerailDialogActions}</DialogActions>
            </Dialog>
            <Dialog
              open={this.state.isRemoveUnitOpen}
              onClose={this.handleRemoveDeviceClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Confirm Unit Removal
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please confirm you'd like to remove this unit.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {
                  <React.Fragment>
                    <Button
                      onClick={this.handleRemoveDeviceClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.removeDerail();
                      }}
                      color="primary"
                    >
                      Confirm
                    </Button>
                  </React.Fragment>
                }
              </DialogActions>
            </Dialog>
            <CloseableSnackbar
              message={"Error creating new derail."}
              open={this.state.isCreatingNewDeviceError}
              onClose={() => {
                this.setState({ isCreatingNewDeviceError: false });
              }}
            />
            <CloseableSnackbar
              message={`Couldn\t find device with thing name: ${this.state.newDeviceThingName}.`}
              open={this.state.isGettingDeviceIdFromThingNameError}
              onClose={() => {
                this.setState({ isGettingDeviceIdFromThingNameError: false });
              }}
            />
            <CloseableSnackbar
              message={"Successfully created new device."}
              open={this.state.createNewDeviceSuccess}
              onClose={() => {
                this.setState({ createNewDeviceSuccess: false });
              }}
            />
            <UnitMapLeftSidebar
              allowedSitesData={this.props.allowedSitesData}
              viewLevel={this.props.viewLevel}
              setMapViewLevel={this.setViewLevel}
            />
            <SlidingMenu
              isOpen={this.state.isBottomSlidingOpen}
              setIsOpen={() =>
                this.setState({
                  isBottomSlidingOpen: !this.state.isBottomSlidingOpen,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "92%",
                    height: "96%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                      height: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SelectableButton
                      key={
                        this.props.viewLevel === VIEW_LEVEL_USA
                          ? "usaViewLevelButton"
                          : "usaViewLevelButton2"
                      }
                      onClick={() => {
                        this.setViewLevel(VIEW_LEVEL_USA);
                      }}
                      text="USA View"
                      isSelected={this.props.viewLevel === VIEW_LEVEL_USA}
                      style={{ height: "45%", width: "100%" }}
                    />
                  </div>

                  <div
                    className="scrollbar"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                      height: "100%",
                      alignItems: "center",
                      overflowY: "auto",
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                  >
                    {Object.keys(this.props.allowedSitesData).map((site) => {
                      return (
                        <SelectableButton
                          key={
                            this.props.viewLevel === site
                              ? `${site}ViewLevelButton`
                              : `${site}ViewLevelButton2`
                          }
                          onClick={() => {
                            this.setViewLevel(site);
                          }}
                          text={formatSite(site)}
                          isSelected={this.props.viewLevel === site}
                          style={{ minHeight: "15%", width: "100%" }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </SlidingMenu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withUnitMap(UnitPickerMapController);
