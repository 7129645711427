import React from "react";
import { io } from "socket.io-client";

import { UnitTypes } from "../../enums/UnitTypes";
import fetchFromAPI from "../../helpers/requestHelper";
import RequestTypes from "../../enums/RequestTypes";
import firebase from "../../../firebase";
import UnitMap from "./UnitMap";
import CloseableSnackbar from "../common/CloseableSnackbar";
import UnitMapLeftSidebar from "../common/UnitMapLeftSidebar";
import RequestReportDialog from "./RequestReportDialog";
import SlidingMenu from "../common/SlidingMenu";
import BaseButton from "../common/BaseButton";
import SelectableButton from "../common/SelectableButton";
import { formatSite } from "../../helpers/stringFormatter";
import UnitMapRightSidebar from "./UnitMapRightSidebar";
import withUnitMap from "../higher_order/withUnitMap";
import { VIEW_LEVEL_USA } from "../../helpers/stringProvider";
import { getUserData } from "../../helpers/firebaseHelper";
import ChangeNicknameDialog from "./ChangeNicknameDialog";
import { updateUnitNickname } from "../../helpers/firebaseHelper";

class DeviceSiteMapController extends React.Component {
  constructor(props) {
    super(props);

    this.setFilterStatus = this.setFilterStatus.bind(this);

    this.handleRequestReportClose = this.handleRequestReportClose.bind(this);
    this.handleChangeNicknameClose = this.handleChangeNicknameClose.bind(this);

    this.submitRequestReport = this.submitRequestReport.bind(this);
    this.submitChangeNickname = this.submitChangeNickname.bind(this);

    this.setClickedUnitIdType = this.setClickedUnitIdType.bind(this);
    this.setViewLevel = this.setViewLevel.bind(this);

    this.userLogout = this.userLogout.bind(this);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();

    this.auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user).then((userData) =>
          this.setState({
            canUserChangeNotifications:
              userData.isAdmin || userData.title === "RL",
            userData: userData,
          })
        );
      }
    });

    this.socket = io();

    this.socket.on("shadowChange", (newShadowData) => {
      if (newShadowData.id) {
        this.props.updateLastShadowDerail(
          newShadowData.id,
          newShadowData.shadow
        );
      }
    });
    this.socket.on("error", (e) => {
      console.log("Error in socket");
      console.log(e);
    });

    if (!localStorage.getItem("filterStatus")) {
      localStorage.setItem("filterStatus", JSON.stringify({}));
    }

    this.state = {
      requestReportOpen: false,
      changeNicknameOpen: false,
      isBottomSlidingOpen: true,
      isFailedRequestSnackbarVisible: false,
      failedRequestSnackbarMessage: "Error sending request!",
      isSuccessfulRequestSnackbarVisible: false,
      clickedUnitId: null,
      clickedUnitType: null,
      canUserChangeNotifications: false,
      filterStatus: JSON.parse(localStorage.getItem("filterStatus")),
      userData: {},
    };

    if (Object.values(this.state.filterStatus).length === 0) {
      Object.values(UnitTypes).forEach((type) => {
        this.state.filterStatus[type] = {};
      });
    }
  }

  // Could be moved to withUnitMap
  setClickedUnitIdType(unitId, unitType, { shouldZoomOntoUnit = false }) {
    this.setState({ clickedUnitId: unitId, clickedUnitType: unitType });
    if (shouldZoomOntoUnit) {
      this.setState({ zoomUpdater: !this.state.zoomUpdater });
    }
  }

  setFilterStatus(unitType, status, value) {
    let newDerailFilterStatus = this.state.filterStatus[unitType];
    newDerailFilterStatus[status] = value;
    let newFilterStatus = this.state.filterStatus;
    newFilterStatus[unitType] = newDerailFilterStatus;
    this.setState({ filterStatus: newFilterStatus });
    localStorage.setItem("filterStatus", JSON.stringify(newFilterStatus));
  }

  handleRequestReportClose() {
    this.setState({ requestReportOpen: false });
  }

  handleChangeNicknameClose() {
    this.setState({ changeNicknameOpen: false });
  }

  submitRequestReport({ month, year, site }) {
    if (!month || !year || !site) {
      this.setState({
        isFailedRequestSnackbarVisible: true,
        failedRequestSnackbarMessage: "Invalid request parameters",
      });
      return;
    }
    fetchFromAPI(RequestTypes.REQUEST_REPORT, {
      queryParameters: { month: month, year: year, site: site },
    }).then((result) => {
      this.handleRequestReportClose();
      if (result.success) {
        this.setState({ isSuccessfulRequestSnackbarVisible: true });
      } else {
        this.setState({
          isFailedRequestSnackbarVisible: true,
          failedRequestSnackbarMessage: result.message,
        });
      }
    });
  }

  submitChangeNickname(unitId, newNickname) {
    this.props.updateUnitsFoundNickname(unitId, newNickname);
    updateUnitNickname(unitId, newNickname);
    this.handleChangeNicknameClose();
  }

  userLogout() {
    this.auth
      .signOut()
      .then(() => {
        this.props.routeChangeLogin();
      })
      .catch((err) => console.log(err));
  }

  setViewLevel(viewLevel) {
    this.setClickedUnitIdType(null, null, {});
    this.setState({ isBottomSlidingOpen: false });
    this.props.setViewLevel(viewLevel);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.toDisplayMap && (
          <React.Fragment>
            <UnitMap
              viewLevel={this.props.viewLevel}
              setViewLevel={this.setViewLevel}
              viewLevelUpdater={this.props.viewLevelUpdater}
              width="100%"
              height="100%"
              unitsFound={this.props.unitsFound}
              allowedSitesData={this.props.allowedSitesData}
              filterStatus={this.state.filterStatus}
              clickedUnitId={this.state.clickedUnitId}
              clickedUnitType={this.state.clickedUnitType}
              setClickedUnitIdType={this.setClickedUnitIdType}
              updateUnitsFoundNickname={this.props.updateUnitsFoundNickname}
              zoomUpdater={this.state.zoomUpdater}
              userData={this.state.userData}
              openChangeNickname={() =>
                this.setState({ changeNicknameOpen: true })
              }
              canUserChangeNotifications={this.state.canUserChangeNotifications}
              onDerailNotificationSettingChange={
                this.props.onDerailNotificationSettingChange
              }
            />
            <RequestReportDialog
              isDialogOpen={this.state.requestReportOpen}
              onClose={this.handleRequestReportClose}
              sites={Object.keys(this.props.allowedSitesData)}
              onSubmit={this.submitRequestReport}
            />
            <UnitMapLeftSidebar
              allowedSitesData={this.props.allowedSitesData}
              viewLevel={this.props.viewLevel}
              setMapViewLevel={this.setViewLevel}
              openRequestReport={() =>
                this.setState({ requestReportOpen: true })
              }
            />
            <UnitMapRightSidebar
              viewLevel={this.props.viewLevel}
              setMapViewLevel={this.setViewLevel}
              openRequestReport={() =>
                this.setState({ requestReportOpen: true })
              }
              setClickedUnitIdType={this.setClickedUnitIdType}
              filterStatus={this.state.filterStatus}
              setFilterStatus={this.setFilterStatus}
              unitsFound={this.props.unitsFound}
              userData={this.state.userData}
              userLogout={this.userLogout}
            />
            <CloseableSnackbar
              open={this.state.isFailedRequestSnackbarVisible}
              onClose={() => {
                this.setState({ isFailedRequestSnackbarVisible: false });
              }}
              message={this.state.failedRequestSnackbarMessage}
            />
            <CloseableSnackbar
              open={this.state.isSuccessfulRequestSnackbarVisible}
              onClose={() => {
                this.setState({ isSuccessfulRequestSnackbarVisible: false });
              }}
              message={"Request sent successfully!"}
            />
            {this.state.clickedUnitId && (
              <ChangeNicknameDialog
                isDialogOpen={this.state.changeNicknameOpen}
                onClose={this.handleChangeNicknameClose}
                unitId={this.state.clickedUnitId}
                unitType={this.state.clickedUnitType}
                unitsFound={this.props.unitsFound}
                onSubmit={this.submitChangeNickname}
              />
            )}

            <SlidingMenu
              isOpen={this.state.isBottomSlidingOpen}
              setIsOpen={() => {
                this.setState({
                  isBottomSlidingOpen: !this.state.isBottomSlidingOpen,
                });
                this.setClickedUnitIdType(null, null, {});
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "92%",
                    height: "96%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                      height: "80%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SelectableButton
                      key={
                        this.props.viewLevel === VIEW_LEVEL_USA
                          ? "usaViewLevelButton"
                          : "usaViewLevelButton2"
                      }
                      onClick={() => {
                        this.setViewLevel(VIEW_LEVEL_USA);
                      }}
                      text="USA View"
                      isSelected={this.props.viewLevel === VIEW_LEVEL_USA}
                      style={{ height: "45%", width: "100%" }}
                    />

                    <BaseButton
                      style={{ height: "45%", width: "100%" }}
                      onClick={() => this.setState({ requestReportOpen: true })}
                    >
                      Request Report
                    </BaseButton>
                  </div>

                  <div
                    className="scrollbar"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                      height: "80%",
                      alignItems: "center",
                      overflowY: "auto",
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                  >
                    {Object.keys(this.props.allowedSitesData).map((site) => {
                      return (
                        <SelectableButton
                          key={
                            this.props.viewLevel === site
                              ? `${site}ViewLevelButton`
                              : `${site}ViewLevelButton2`
                          }
                          onClick={() => {
                            this.setViewLevel(site);
                          }}
                          text={formatSite(site)}
                          isSelected={this.props.viewLevel === site}
                          style={{ minHeight: "15%", width: "100%" }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </SlidingMenu>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withUnitMap(DeviceSiteMapController);
