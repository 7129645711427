import React from "react";
import ReactMapboxGl, { Source, Popup } from "react-mapbox-gl";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";

import HoveredPopup from "../common/HoveredPopup";
import { getUnitStatusDerail } from "../../helpers/derailToInfo";
import { unitTypeToStatuses } from "../../enums/UnitStatuses";
import { UnitTypes } from "../../enums/UnitTypes";
import { siteToZoom } from "../../helpers/siteHelper";
import { createLayerComponent } from "../../helpers/unitStyleHelper";
import { formatSite, formatThingName } from "../../helpers/stringFormatter";
import "../../css/scroll.css";
import MapSitesOverlay from "../common/MapSitesOverlay";
import BaseButton from "../common/BaseButton";
import { VIEW_LEVEL_USA } from "../../helpers/stringProvider";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoidHlsZXJtY2RvbmFsZCIsImEiOiJja2locXB2MWgwNmh0MndtbzgwZnh5cWJ4In0.w-Mvtis4wJCm22WECsrZWQ",
  interactive: true,
  dragRotate: false,
  pitchWithRotate: false,
  touchZoomRotate: true,
  attributionControl: false,
  doubleClickZoom: false,
});

class UnitPickerMap extends React.Component {
  constructor(props) {
    super(props);

    this.GPS_THRESHOLD = 0.00001;

    this.RETURN_TO_SITE_COORD_THRESHOLD = 0.01;
    this.RETURN_TO_SITE_ZOOM_THRESHOLD = 2;

    this.ENTERED_SITE_COORD_THRESHOLD = 0.01;
    this.ENTERED_SITE_ZOOM_THRESHOLD = 2;

    this.width = props.width || "800px";
    this.height = props.height || "800px";
    this.timeoutID = undefined;

    this.calculateSourcesAndLayersDerail =
      this.calculateSourcesAndLayersDerail.bind(this);

    this.setMapViewLevel = this.setMapViewLevel.bind(this);

    this.checkIfUserEnteredSite = this.checkIfUserEnteredSite.bind(this);

    this.USA_VIEW_LEVEL_DATA = {
      zoom: isMobile ? [3.2] : [4.2],
      center: [-95.7129, 37.0902],
    };

    this.state = {
      bearing: [0],
      unitToLatLongMap: {},
      hoveredUnitId: null,
      hoveredUnitType: null,
      hoveredSite: null,
    };

    this.state.center =
      (props.allowedSitesData[props.viewLevel] ?? {}).center ??
      this.USA_VIEW_LEVEL_DATA.center;
    this.state.zoom = this.USA_VIEW_LEVEL_DATA.zoom;
    if (props.viewLevel !== VIEW_LEVEL_USA && props.allowedSitesData) {
      this.state.zoom = siteToZoom(props.viewLevel, props.allowedSitesData, {
        isMobile: isMobile,
      });
    }
  }

  checkIfUserEnteredSite() {
    for (const site of Object.keys(this.props.allowedSitesData)) {
      const siteData = this.props.allowedSitesData[site];
      const siteCenter = siteData.center;
      const currMapCenter = [
        this.map.getCenter().lng,
        this.map.getCenter().lat,
      ];
      const offRadius = Math.pow(
        Math.pow(siteCenter[0] - currMapCenter[0], 2) +
          Math.pow(siteCenter[1] - currMapCenter[1], 2),
        0.5
      );
      if (offRadius <= this.ENTERED_SITE_COORD_THRESHOLD) {
        const siteZoom = isMobile ? siteData.mobileZoom : siteData.webZoom;
        if (
          Math.abs(siteZoom - this.map.getZoom()) <=
          this.ENTERED_SITE_ZOOM_THRESHOLD
        ) {
          return site;
        }
      }
    }
    return null;
  }

  setMapViewLevel(newViewLevel) {
    let newMapData;
    if (newViewLevel === VIEW_LEVEL_USA) {
      newMapData = this.USA_VIEW_LEVEL_DATA;
    } else {
      const siteData = this.props.allowedSitesData[newViewLevel];
      if (!siteData) {
        return;
      }
      newMapData = {
        zoom: isMobile ? siteData.mobileZoom : siteData.webZoom,
        center: siteData.center,
      };
    }

    this.map.flyTo(newMapData);
  }

  onStyleLoad = (m) => {
    this.map = m;

    this.map.on("click", (e) => {
      const feature = this.map.queryRenderedFeatures(e.point)[0];
      if (
        feature &&
        feature.properties &&
        feature.properties.unitId &&
        feature.properties.unitType
      ) {
        const clickedUnitId = feature.properties.unitId;
        const clickedUnitType = feature.properties.unitType;
        if (this.props.clickedUnitId === clickedUnitId) {
          this.props.setClickedUnitIdType(null, null);
        } else {
          this.props.setClickedUnitIdType(clickedUnitId, clickedUnitType);
        }
      } else {
        // User didn't click on a device and may want to place one
        this.props.setClickedUnitIdType(null, null);
        const feature = this.map.queryRenderedFeatures(e.point)[0];
        if (feature && feature.properties && feature.properties.viewLevel) {
          // Don't show popup if clicking on a site
          return;
        }
        const newCoordinates = [
          parseFloat(e.lngLat.lng.toFixed(6)),
          parseFloat(e.lngLat.lat.toFixed(6)),
        ];
        this.props.setClickedCoordinates(newCoordinates);
      }
    });

    this.map.on("dragend", (e) => {
      this.props.updateMapLocalStorage({
        zoom: this.map.getZoom(),
        lat: this.map.getCenter().lat,
        lng: this.map.getCenter().lng,
      });
    });

    this.map.on("zoomend", (e) => {
      this.props.updateMapLocalStorage({
        zoom: this.map.getZoom(),
        lat: this.map.getCenter().lat,
        lng: this.map.getCenter().lng,
      });
    });

    this.map.on("mousemove", (e) => {
      if (!isMobile) {
        const feature = this.map.queryRenderedFeatures(e.point)[0];
        if (
          feature &&
          feature.properties &&
          feature.properties.unitId &&
          feature.properties.unitType
        ) {
          const hoveredUnitId = feature.properties.unitId;
          const hoveredUnitType = feature.properties.unitType;

          if (hoveredUnitId !== this.state.hoveredUnitId) {
            this.setState({
              hoveredUnitId: hoveredUnitId,
              hoveredUnitType: hoveredUnitType,
            });
          }
        } else if (!feature) {
          this.setState({ hoveredUnitId: null, hoveredUnitType: null });
        }
      }
    });
    let pointLayers = [];
    Object.values(UnitTypes).forEach((type) => {
      const statuses = unitTypeToStatuses(type);
      Object.values(statuses).forEach((status) => {
        const layerTitle = type + status + "Layer";
        pointLayers.push(layerTitle);
      });
    });

    this.map.on("mouseenter", "siteCentersLayer", (e) => {
      if (!isMobile) {
        if (e.features.length > 0) {
          if (e.features[0].properties && e.features[0].properties.siteName) {
            const site = formatSite(e.features[0].properties.siteName);
            const coordinates =
              this.props.allowedSitesData[e.features[0].properties.siteName]
                .center;
            this.setState({
              hoveredSite: site,
              hoveredSiteCoordinates: coordinates,
            });
          }
        }
      }
    });

    this.map.on("mouseleave", "siteCentersLayer", (e) => {
      this.setState({ hoveredSite: null, hoveredSiteCoordinates: null });
    });

    this.map.on("click", "siteCentersLayer", (e) => {
      if (e.features.length > 0) {
        if (e.features[0].properties && e.features[0].properties.viewLevel) {
          this.props.setViewLevel(e.features[0].properties.viewLevel);
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.viewLevel !== prevProps.viewLevel) {
      this.setMapViewLevel(this.props.viewLevel);
    }
    // Meant to refresh map location if user presses same view level again
    else if (this.props.viewLevelUpdater !== prevProps.viewLevelUpdater) {
      this.setMapViewLevel(this.props.viewLevel);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  shouldComponentUpdate() {
    return true;
  }

  calculateSourcesAndLayersDerail() {
    let statusToFeatureListMap = {};
    if ((this.props.unitsFound ?? {})[UnitTypes.DERAIL]) {
      Object.keys(this.props.unitsFound[UnitTypes.DERAIL]).forEach((id) => {
        const unit = this.props.unitsFound[UnitTypes.DERAIL][id];

        const shadow = this.props.unitsFound[UnitTypes.DERAIL][id].shadow;
        const unitStatus = getUnitStatusDerail(unit, shadow);

        if (unit && unit.coordinates) {
          const mapFeature = {
            type: "Feature",
            geometry: { type: "Point", coordinates: unit.coordinates },
            properties: { unitId: id, unitType: UnitTypes.DERAIL },
          };

          let newFeatureList = statusToFeatureListMap[unitStatus] ?? [];

          newFeatureList.push(mapFeature);
          statusToFeatureListMap[unitStatus] = newFeatureList;
        }
      });
    }
    return statusToFeatureListMap;
  }

  render() {
    let sourceList = [];
    let layerList = [];
    Object.values(UnitTypes).forEach((type) => {
      let sourcesAndLayers;
      switch (type) {
        case UnitTypes.DERAIL:
          sourcesAndLayers = this.calculateSourcesAndLayersDerail();
          break;
        default:
          return;
      }
      const statuses = unitTypeToStatuses(type);
      Object.values(statuses).forEach((status) => {
        const sourceTitle = type + status + "Source";
        const newSource = (
          <Source
            id={sourceTitle}
            key={sourceTitle}
            geoJsonSource={{
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: sourcesAndLayers[status] ?? [],
              },
            }}
          />
        );
        sourceList.push(newSource);

        const layerTitle = type + status + "Layer";
        const newLayer = createLayerComponent(
          type,
          status,
          sourceTitle,
          layerTitle,
          this.props.filterStatus
        );
        layerList.push(newLayer);
      });
    });
    let hoveredPopup = <div />;
    if (
      this.state.hoveredUnitId &&
      this.state.hoveredUnitId !== this.props.clickedUnitId
    ) {
      hoveredPopup = (
        <HoveredPopup
          key={this.state.hoveredUnitId}
          unitId={this.state.hoveredUnitId}
          unitType={this.state.hoveredUnitType}
          unitsFound={this.props.unitsFound ?? {}}
          isClickable={false}
        />
      );
    }

    let clickedPopup = <div />;
    if (this.props.clickedUnitId) {
      const unit =
        this.props.unitsFound[this.props.clickedUnitType][
          this.props.clickedUnitId
        ] ?? {};
      if (unit) {
        const formattedThingName = formatThingName(unit.thingName);
        const coordinates = unit.coordinates;
        if (coordinates) {
          clickedPopup = (
            <Popup
              coordinates={coordinates}
              offset={{
                "bottom-left": [0, 0],
                bottom: [0, 0],
                "bottom-right": [0, 0],
              }}
            >
              <h1>{unit.safeyardNickname ?? formattedThingName}</h1>
              <div>
                <Button
                  variant="contained"
                  style={{
                    marginTop: "8px",
                    marginBotton: "8px",
                    backgroundColor: "red",
                  }}
                  onClick={() => {
                    this.props.setIsRemoveUnitOpen(true);
                  }}
                >
                  <b>Remove Unit</b>
                </Button>
              </div>
            </Popup>
          );
        }
      }
    }

    let sitePopup = <div />;
    if (this.state.hoveredSite) {
      sitePopup = (
        <Popup
          coordinates={this.state.hoveredSiteCoordinates}
          key={this.state.hoveredSite}
        >
          <h1>{this.state.hoveredSite}</h1>
        </Popup>
      );
    }

    return (
      <React.Fragment>
        <Map
          style="mapbox://styles/tylermcdonald/ckolq4qbb0ch917q6eq008xsk"
          containerStyle={{ height: this.height, width: this.width }}
          center={this.state.center}
          zoom={this.state.zoom}
          bearing={this.state.bearing}
          onStyleLoad={this.onStyleLoad}
          flyToOptions={{
            speed: 1.6,
          }}
        >
          <MapSitesOverlay sitesData={this.props.allowedSitesData} />
          {sourceList}
          {layerList}
          {hoveredPopup}
          {clickedPopup}
          {sitePopup}

          {this.props.clickedCoordinates && (
            <Popup coordinates={this.props.clickedCoordinates}>
              <p>
                Lat: {this.props.clickedCoordinates[1]}, Lng:{" "}
                {this.props.clickedCoordinates[0]}
              </p>
              <BaseButton
                onClick={() => {
                  this.props.setIsNewDerailDialogOpen(true);
                }}
              >
                Place Derail
              </BaseButton>
            </Popup>
          )}
        </Map>
      </React.Fragment>
    );
  }
}

export default withRouter(UnitPickerMap);
