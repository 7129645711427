import React, { useState } from "react";
import { DateTime, Info } from "luxon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

import { formatSite } from "../../helpers/stringFormatter";

const RequestReportDialog = ({ sites, isDialogOpen, onClose, onSubmit }) => {
  const [site, setSite] = useState(sites[0] ?? "");
  const [month, setMonth] = useState(DateTime.utc().monthLong);
  const [year, setYear] = useState(DateTime.utc().year);

  const BASE_REQUEST_REPORT_YEAR = 2021;
  const REQUEST_YEAR_RANGE = DateTime.utc().year - BASE_REQUEST_REPORT_YEAR + 1;

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Request Report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select what site, month, and year you would like for the report.
        </DialogContentText>
        <div style={{ display: "flex" }}>
          <div>
            <InputLabel id="site-select-label">
              <b>Site</b>
            </InputLabel>
            <Select
              style={{ width: "8vw" }}
              labelId="site-select-label"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              {sites.map((site) => {
                return (
                  <MenuItem key={site} value={site}>
                    {formatSite(site)}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div style={{ marginLeft: "1vw" }}>
            <InputLabel id="month-select-label">
              <b>Month</b>
            </InputLabel>
            <Select
              style={{ width: "8vw" }}
              labelId="month-select-label"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              {Info.months().map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ marginLeft: "1vw" }}>
            <InputLabel id="year-select-label">
              <b>Year</b>
            </InputLabel>
            <Select
              style={{ width: "8vw" }}
              labelId="year-select-label"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {Array.from(new Array(REQUEST_YEAR_RANGE), (_, year) => (
                <MenuItem key={year} value={year + BASE_REQUEST_REPORT_YEAR}>
                  {year + BASE_REQUEST_REPORT_YEAR}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit({ month: month, year: year, site: site });
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RequestReportDialog;
