import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

import "./index.css";
import App from "./App/App";
import { getAccentColor, getThemeColor } from "./App/helpers/styleProvider";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: getThemeColor(),
    },
    secondary: {
      main: getAccentColor(),
    },
  },

  overrides: {
    Button: {
      raisedSecondary: {
        color: "red",
      },
    },
  },
});

render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
