import React from "react";
import styled from "styled-components";

import SideBarButton from "./SideBarButton";
import { formatSite } from "../../helpers/stringFormatter";
import { VIEW_LEVEL_USA } from "../../helpers/stringProvider";
import { getThemeColor } from "../../helpers/styleProvider";

const LeftBar = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  display: flex;
  @media (max-width: 980px) {
    display: none;
  }
  @media (min-width: 981px) {
    min-width: 16vw;
  }
  @media (min-width: 1201px) {
    max-width: 196px;
    min-width: 196px;
  }
`;

const LeftBarStyled = styled.div`
  padding-top: 8vh;
  background-color: ${getThemeColor()};
  @media (max-width: 980px) {
    display: none;
  }
  @media (min-width: 981px) {
    padding-left: 2vw;
    min-width: 16vw;
  }
  @media (min-width: 1201px) {
    padding-left: 24px;
    max-width: 196px;
    min-width: 196px;
  }
`;

const UnitMapLeftSideBar = ({
  viewLevel,
  setMapViewLevel,
  allowedSitesData,
  openRequestReport,
}) => (
  <LeftBar>
    <LeftBarStyled style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "172px",
          }}
        >
          <h3 style={{ color: "#FFF", margin: "0px" }}>View</h3>
          <SideBarButton
            isReplacedWhenSmall={false}
            text="USA"
            key={
              viewLevel === VIEW_LEVEL_USA
                ? "usaViewLevelButton"
                : "usaViewLevelButton2"
            }
            onClick={() => {
              setMapViewLevel(VIEW_LEVEL_USA);
            }}
            isPressed={viewLevel === VIEW_LEVEL_USA}
          />
          <p
            style={{
              padding: ".5em 0",
              margin: "0",
              color: "#FFF",
              fontSize: "18px",
            }}
          >
            Sites
          </p>
          <div
            className="scrollbar"
            style={{
              overflowY: "auto",
              overflowX: "visible",
              display: "flex",
              flexDirection: "column",
              maxHeight: "50vh",
              paddingLeft: "2px",
              width: "100%",
            }}
          >
            {Object.values(allowedSitesData).map((siteData) => {
              return (
                <SideBarButton
                  isReplacedWhenSmall={false}
                  text={formatSite(siteData.site)}
                  key={
                    viewLevel === siteData.site
                      ? `${siteData.site}ViewLevelButton`
                      : `${siteData.site}ViewLevelButton2`
                  }
                  onClick={() => {
                    setMapViewLevel(siteData.site);
                  }}
                  isPressed={viewLevel === siteData.site}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            marginBottom: "8vh",
            flexDirection: "column",
            minHeight: "172px",
          }}
        >
          {openRequestReport && (
            <SideBarButton
              isReplacedWhenSmall={true}
              text="Request Report"
              onClick={() => {
                openRequestReport();
              }}
              isPressed={false}
            />
          )}
        </div>
      </div>
    </LeftBarStyled>
  </LeftBar>
);
export default UnitMapLeftSideBar;
