export function formatStatus(status) {
  if (status === undefined) {
    return "N/A";
  }
  let newStatus = status;
  newStatus = newStatus.toLowerCase();
  newStatus = newStatus.replaceAll("_", " ");
  newStatus = newStatus
    .split(" ")
    .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1))
    .join(" ");
  newStatus = newStatus.substring(0, 1).toUpperCase() + newStatus.substring(1);
  return newStatus;
}

export function formatSite(site) {
  if (!site) {
    return "N/A";
  }
  if (site === "usa") {
    return "USA";
  }

  let newSite = site;
  newSite = newSite.toLowerCase();
  newSite = newSite.replaceAll("_", " ");
  newSite = newSite
    .split(" ")
    .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1))
    .join(" ");
  newSite = newSite.substring(0, 1).toUpperCase() + newSite.substring(1);
  return newSite;
}

export function formatThingName(thingName) {
  if (!thingName) {
    return "N/A";
  }
  return thingName;
}
