import React from "react";
import { Snackbar, SnackbarContent, Button } from "@material-ui/core";

import { getThemeColor } from "../../helpers/styleProvider";

const CloseableSnackbar = ({ open, onClose, message }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    onClose={onClose}
  >
    <SnackbarContent
      style={{
        backgroundColor: getThemeColor(),
      }}
      message={message}
      action={
        <React.Fragment>
          <Button variant="text" color="secondary" onClick={onClose}>
            Close
          </Button>
        </React.Fragment>
      }
    />
  </Snackbar>
);
export default CloseableSnackbar;
