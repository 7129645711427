import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBi5fVIBQojgB5LAdRfImQghCTDuWyrKR4",
  authDomain: "safeyard-2511b.firebaseapp.com",
  projectId: "safeyard-2511b",
  storageBucket: "safeyard-2511b.appspot.com",
  messagingSenderId: "950257009958",
  appId: "1:950257009958:web:94d5ef9628ed13e8a76de7",
};
firebase.initializeApp(config);

export default firebase;
