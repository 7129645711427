export function siteToZoom(site, siteData, { isMobile = false }) {
  if (site in siteData) {
    if (isMobile) {
      return siteData[site].mobileZoom;
    } else {
      return siteData[site].webZoom;
    }
  }

  return 1;
}
