import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import DeviceSiteMapPage from "./pages/DeviceSiteMapPage";
import ReportPage from "./pages/ReportPage";
import UnitMapPickerPage from "./pages/UnitMapPickerPage";
import AdminPage from "./pages/AdminPage";

export const theme = {
  main: "#112337",
  accent: "#FFF",
  pressed_accent: "#FFF",
  unpressed_action: "#112337",
};

class App extends Component {
  render() {
    const App = () => (
      <div>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/railserve" component={DeviceSiteMapPage} />
          <Route path="/report" component={ReportPage} />
          <Route path="/unit-picker" component={UnitMapPickerPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/*" component={LoginPage} />
        </Switch>
      </div>
    );
    return <App />;
  }
}

export default App;
