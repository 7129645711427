import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Div100vh from "react-div-100vh";
import { ThemeProvider } from "styled-components";

import DeviceSiteMapController from "../components/unitmap/DeviceSiteMapController";
import firebase from "../../firebase";
import { theme } from "../App";

class DeviceSiteMapPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleResetPasswordSubmit = this.handleResetPasswordSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.routeChangeLogin = this.routeChangeLogin.bind(this);
    this.checkUserPasswordReset = this.checkUserPasswordReset.bind(this);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();

    this.auth.onAuthStateChanged((user) => {
      if (!user) {
        this.routeChangeLogin();
      } else {
        this.checkUserPasswordReset();
      }
    });
  }
  state = {
    resetPasswordOpen: false,
    newPassword: "",
    oldPassword: "",
  };

  async checkUserPasswordReset() {
    const userRef = this.firestore.doc(`users/${this.auth.currentUser.uid}`);
    const userDoc = await userRef.get();
    if (userDoc.exists) {
      const userData = userDoc.data();
      if ("hasResetPassword" in userData) {
        if (!userData.hasResetPassword) {
          this.setState({ resetPasswordOpen: true });
        }
      }
    } else {
      this.routeChangeLogin();
    }
  }

  routeChangeLogin() {
    this.props.history.push("login");
  }

  handleClose() {
    this.setState({ resetPasswordOpen: false });
  }

  async handleResetPasswordSubmit() {
    if (this.state.newPassword.length >= 6) {
      this.auth.currentUser
        .updatePassword(this.state.newPassword)
        .then(async () => {
          const userRef = this.firestore.doc(
            `users/${this.auth.currentUser.uid}`
          );
          await userRef.update({ hasResetPassword: true });

          this.setState({ passwordError: null, resetPasswordOpen: false });
        })
        .catch((error) => {
          if (error.code === "auth/requires-recent-login") {
            const credential = firebase.auth.EmailAuthProvider.credential(
              this.auth.currentUser.email,
              this.state.oldPassword
            );
            this.auth.currentUser
              .reauthenticateWithCredential(credential)
              .then(() => {
                this.auth.currentUser
                  .updatePassword(this.state.newPassword)
                  .then(async () => {
                    const userRef = this.firestore.doc(
                      `users/${this.auth.currentUser.uid}`
                    );
                    await userRef.update({ hasResetPassword: true });

                    this.setState({
                      passwordError: null,
                      resetPasswordOpen: false,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                    this.setState({
                      passwordError:
                        "There was an error updating your password. Please try again.",
                    });
                  });
              })
              .catch((error) => {
                if (error.code === "auth/wrong-password") {
                  this.setState({
                    oldPasswordError: "Incorrect old password.",
                  });
                } else {
                  console.log(error);
                  this.setState({
                    passwordError:
                      "There was an error updating your password. Please try again.",
                  });
                }
              });
          } else {
            console.log(error);
            this.setState({
              passwordError:
                "There was an error updating your password. Please try again.",
            });
          }
        });
    } else {
      this.setState({ passwordError: "Password does not match requirements." });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Div100vh>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.resetPasswordOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Change Default Password
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                We noticed you're still using the default password provided to
                this account. Please enter a new password here and use it for
                future sign-ins.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Old Password"
                type="password"
                error={this.state.oldPasswordError ? true : false}
                helperText={this.state.oldPasswordError}
                value={this.state.oldPassword}
                onChange={(e) => {
                  this.setState({
                    oldPassword: e.target.value,
                    oldPasswordError: null,
                  });
                }}
                fullWidth
              />
              <TextField
                margin="dense"
                label="New Password"
                type="password"
                error={this.state.passwordError ? true : false}
                helperText={
                  this.state.passwordError
                    ? `${this.state.passwordError} Must be at least 6 characters.`
                    : "Must be at least 6 characters."
                }
                value={this.state.newPassword}
                onChange={(e) => {
                  this.setState({ newPassword: e.target.value });
                }}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleResetPasswordSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <DeviceSiteMapController routeChangeLogin={this.routeChangeLogin} />
        </Div100vh>
      </ThemeProvider>
    );
  }
}

export default withRouter(DeviceSiteMapPage);
