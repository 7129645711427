import React from "react";
import { Button, FormGroup, FormLabel, FormControl } from "@material-ui/core";
import { Popup } from "react-mapbox-gl";

import { formatStatus, formatThingName } from "../../helpers/stringFormatter";
import { getUnitStatusDerail } from "../../helpers/derailToInfo";
import NotificationSwitch from "../unitmap/NotificationSwitch";

class ClickedPopup extends React.PureComponent {
  render() {
    if (this.props.unitId) {
      const unit =
        this.props.unitsFound[this.props.unitType][this.props.unitId] ?? {};
      if (unit) {
        const formattedThingName = formatThingName(unit.thingName);
        const coordinates = unit.coordinates;
        if (!coordinates) {
          return <div />;
        }
        const shadow = unit.shadow ?? {};
        const unitStatus = getUnitStatusDerail(unit, shadow);
        const batteryFormat = shadow.bat ? `${shadow.bat} mV` : "Not reported";
        let subtitle = (
          <div>
            {unit.safeyardNickname !== formattedThingName && (
              <React.Fragment>
                <strong>Assigned Name: </strong>
                {formattedThingName}
                <br />
              </React.Fragment>
            )}
            <strong>Status:</strong> {formatStatus(unitStatus)}
            <br />
            <strong>Battery Level:</strong> {batteryFormat}
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "8px", marginBotton: "8px" }}
                onClick={() => {
                  this.props.openChangeNickname();
                }}
              >
                Change Nickname
              </Button>
              <FormControl component="fieldset" style={{ marginTop: ".5em" }}>
                <FormLabel component="legend">Notifications</FormLabel>
                <FormGroup>
                  <NotificationSwitch
                    canUserChangeNotifications={
                      this.props.canUserChangeNotifications
                    }
                    isEnabled={unit.isSiteLeaderNotificationsEnabled}
                    onChange={(e) => {
                      this.props.onDerailNotificationSettingChange(
                        this.props.unitId,
                        e.target.checked,
                        { isSiteLeader: true, isSeniorSiteLeader: true }
                      );
                    }}
                    text="Site Leader"
                  />
                  <NotificationSwitch
                    canUserChangeNotifications={
                      this.props.canUserChangeNotifications
                    }
                    isEnabled={unit.isRegionalLeaderNotificationsEnabled}
                    onChange={(e) => {
                      this.props.onDerailNotificationSettingChange(
                        this.props.unitId,
                        e.target.checked,
                        { isRegionalLeader: true }
                      );
                    }}
                    text="Regional Leader"
                  />
                  {(this.props.userData.isAdmin ||
                    this.props.userData.title === "Executive") && (
                    <NotificationSwitch
                      canUserChangeNotifications={true}
                      isEnabled={unit.addedNotificationUids?.includes(
                        this.props.userData.id
                      )}
                      onChange={(e) => {
                        this.props.onDerailNotificationSettingChange(
                          this.props.unitId,
                          e.target.checked,
                          { otherUids: [this.props.userData.id] }
                        );
                      }}
                      text="Notifications for You"
                    />
                  )}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        );
        return (
          <Popup
            coordinates={coordinates}
            key={this.props.unitId}
            offset={{
              "bottom-left": [0, 0],
              bottom: [0, 0],
              "bottom-right": [0, 0],
            }}
          >
            <h1>{unit.safeyardNickname ?? unit.thingName ?? ""}</h1>
            <div>{subtitle}</div>
          </Popup>
        );
      }
    } else {
      // TODO Handle other unit types
    }
  }
}

export default ClickedPopup;
