import { DerailStatus } from "../enums/UnitStatuses";

export function getUnitStatusDerail(unit, shadow) {
  // TODO Add check in Events table for disconnection event w/ no reconnection
  if (
    !shadow ||
    !shadow?.Errors ||
    !unit?.shadow?.UnitState?.device_connected ||
    !unit?.shadow?.UnitState?.gateway_connected
  ) {
    return DerailStatus.DISCONNECTED;
  } else {
    if (shadow.Errors.wf10 === undefined) {
      return DerailStatus.DISCONNECTED;
    }
    if (shadow.Errors.wf10 === 0) {
      return DerailStatus.DERAIL_OFF;
    }
    if (shadow.Errors.wf10 === 1) {
      return DerailStatus.DERAIL_ON;
    }
  }
}
