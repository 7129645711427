import firebase from "../../firebase";

import RequestTypes from "../enums/RequestTypes";

const auth = firebase.auth();

export default async function fetchFromAPI(
  requestType,
  { parameters = {}, queryString = "", queryParameters = {}, data = {} }
) {
  let url;
  let method = "GET";
  switch (requestType) {
    case RequestTypes.UNITS:
      url = "/api/units";
      break;
    case RequestTypes.UNIT:
      if (parameters.id) {
        url = `/api/unit/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.UNIT_SHADOW:
      if (parameters.id) {
        url = `/api/unit-shadow/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.SUBSCRIBE:
      if (parameters.id) {
        url = `/api/subscribe/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.UNSUBSCRIBE:
      if (parameters.id) {
        url = `/api/unsubscribe/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.UNIT_SUBSCRIPTIONS:
      if (parameters.id) {
        url = `/api/unit/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.SHADOW_CHANGE_LOG:
      url = "/api/shadow-change-log";
      break;
    case RequestTypes.GENERATE_PDF:
      url = "/api/generate-pdf";
      break;
    case RequestTypes.REQUEST_REPORT:
      url = "/api/request-report-email";
      break;
    case RequestTypes.PULL_NEW_DEVICE:
      if (parameters.id) {
        url = `/api/pull-new-device/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.GET_DEVICE_ID:
      if (parameters.thingName) {
        url = `/api/get-device-id/${parameters.thingName}`;
      } else {
        return {
          success: false,
          message: "No unit thing name included in parameters",
        };
      }
      break;
    case RequestTypes.REFRESH_BACKEND:
      url = "/api/refresh-backend";
      break;
    case RequestTypes.ADD_UIDS_TO_DEVICE_NOTIFICATIONS:
      if (parameters.id) {
        url = `/api/add-uids-to-device-notifications/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.REMOVE_UIDS_FROM_DEVICE_NOTIFICATIONS:
      if (parameters.id) {
        url = `/api/remove-uids-from-device-notifications/${parameters.id}`;
      } else {
        return { success: false, message: "No unit id included in parameters" };
      }
      break;
    case RequestTypes.CREATE_USER:
      url = "/api/create-user";
      break;
    default:
      return { success: false, message: "Invalid request type" };
  }

  if (queryString) {
    url = `${url}?${queryString}`;
  } else if (queryParameters) {
    url += "?";
    Object.keys(queryParameters).forEach((queryKey) => {
      if (
        typeof queryParameters[queryKey] === "object" ||
        Array.isArray(queryParameters[queryKey])
      ) {
        url += `${queryKey}=${JSON.stringify(queryParameters[queryKey])}&`;
      } else {
        url += `${queryKey}=${queryParameters[queryKey]}&`;
      }
    });
    url = url.substring(0, url.length - 1);
  }

  const user = auth.currentUser;
  const token = user && (await user.getIdToken());

  const res = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}
