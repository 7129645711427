import React, { useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useHistory } from "react-router-dom";

import firebase from "../../firebase";
import BaseButton from "../components/common/BaseButton";
import fetchFromAPI from "../helpers/requestHelper";
import RequestTypes from "../enums/RequestTypes";
import { getThemeColor } from "../helpers/styleProvider";
import { isValidSiteData, isValidUserData } from "../helpers/schemaProvider";
import UploadJsonComponent from "../components/admin/UploadJsonComponent";

const AdminPage = () => {
  let history = useHistory();
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        history.push("/login");
      } else {
        const userRef = firebase.firestore().doc(`users/${user.uid}`);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          if ("isAdmin" in userData) {
            if (userData.isAdmin) {
              return;
            }
          }
        }
        history.push("/railserve");
      }
    });
  }, []);

  const refreshBackendData = async () => {
    const resp = await fetchFromAPI(RequestTypes.REFRESH_BACKEND, {});
    if (resp.success) {
      console.log(resp.success);
    }
  };
  const handleSiteDataSubmit = async (data) => {
    // TODO: Handle deleteAttributes
    let invalidSites = [];
    let siteToSiteDoc = {};
    for (const [site, siteData] of Object.entries(data)) {
      const siteDoc = await firestore
        .collection("site_metadata")
        .doc(site)
        .get();
      if (siteDoc.exists) {
        if (!isValidSiteData(siteData, { isNewSite: false })) {
          invalidSites.push(site);
        } else {
          siteToSiteDoc[site] = siteDoc;
        }
      } else {
        if (!isValidSiteData(siteData, { isNewSite: true })) {
          invalidSites.push(site);
        }
      }
    }

    for (const [site, siteData] of Object.entries(data)) {
      if (invalidSites.includes(site)) {
        continue;
      }
      const siteRef = firestore.collection("site_metadata").doc(site);
      const siteDoc = siteToSiteDoc[site];
      if (siteData.lat && siteData.lng) {
        siteData.center = [siteData.lng, siteData.lat];
      } else if (siteData.lat || siteData.lng) {
        if (!siteDoc) {
          // This should never happen if JSON schema requires lat & lng for a new site
          invalidSites.push(site);
          continue;
        }
        siteData.center = [
          siteData.lng ?? siteDoc.data().center[0],
          siteData.lat ?? siteDoc.data().center[1],
        ];
      }
      delete siteData.lng;
      delete siteData.lat;

      if (siteData?.mobileZoom) {
        siteData.mobileZoom = [siteData.mobileZoom];
      }
      if (siteData?.webZoom) {
        siteData.webZoom = [siteData.webZoom];
      }

      if (siteDoc?.exists) {
        if (siteData.deleteAttributes) {
          const deleteData = siteData.deleteAttributes.reduce(
            (accumulator, currentValue) => {
              accumulator[currentValue] =
                firebase.firestore.FieldValue.delete();
              return accumulator;
            },
            {}
          );
          await siteDoc.ref.update(deleteData);
          delete siteData.deleteAttributes;
        }

        await siteRef.update(siteData);
      } else {
        await siteRef.set(siteData);
      }
    }
    if (invalidSites.length !== 0) {
      let invalidSitesString = invalidSites.join(", ");
      invalidSitesString = invalidSitesString.substring(
        0,
        invalidSitesString.length - 2
      );
      return {
        success: false,
        invalidFileMessage: `Partially invalid file. Failed on sites: ${invalidSitesString}`,
      };
    } else {
      return { success: true };
    }
  };

  const handleUserDataSubmit = async (data) => {
    if (!Array.isArray(data.users)) {
      return {
        success: false,
        invalidFileMessage: "No root array named users",
      };
    }

    let invalidUsers = {};
    for (let i = 0; i < data.users.length; i++) {
      if (!isValidUserData(data.users[i])) {
        invalidUsers[i] = "Invalid JSON.";
      } else {
        const resp = await fetchFromAPI(RequestTypes.CREATE_USER, {
          queryParameters: { userData: data.users[i] },
        });
        if (!resp.success) {
          invalidUsers[i] = resp.message;
        }
      }
    }
    if (Object.keys(invalidUsers).length === 0) {
      return { success: true };
    } else {
      return {
        success: false,
        invalidFileMessage: `Errors: Failed on user indices (0-indexed): ${Object.keys(
          invalidUsers
        )
          .map((key) => key + ": " + invalidUsers[key])
          .join(",")}`,
      };
    }
  };

  return (
    <Div100vh style={{ backgroundColor: getThemeColor(), padding: 0 }}>
      <div
        style={{
          color: "white",
          backgroundColor: getThemeColor(),
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ padding: 0, margin: 0 }}>Admin Page</h1>
          <BaseButton
            style={{
              width: "70%",
              minWidth: "8em",
              margin: "2em 0",
              height: "4em",
            }}
            onClick={refreshBackendData}
          >
            Refresh Backend Data
          </BaseButton>
          <UploadJsonComponent
            title="Site Metadata"
            handleDataSubmit={handleSiteDataSubmit}
          />
          <div style={{ height: "2em" }} />
          <UploadJsonComponent
            title="User Data"
            handleDataSubmit={handleUserDataSubmit}
          />
        </div>
      </div>
    </Div100vh>
  );
};

export default AdminPage;
