import React, { useState } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
  withStyles,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";

import firebase from "../../firebase";
import { getThemeColor } from "../helpers/styleProvider";
import fetchFromAPI from "../helpers/requestHelper";
import RequestTypes from "../enums/RequestTypes";
import { formatSite } from "../helpers/stringFormatter";
import { getAllowedSites } from "../helpers/firebaseHelper";
import BaseButton from "../components/common/BaseButton";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const StyledSelect = withStyles({
  icon: {
    color: "white",
  },
})(Select);

const ReportPage = () => {
  let history = useHistory();
  const auth = firebase.auth();

  const classes = useStyles();

  const [selectedSite, setSelectedSite] = useState("");
  const [today, _] = useState(DateTime.now());
  const [allowedSites, setAllowedSites] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [pdfBase64, setPdfBase64] = useState(null);
  const [requesterId, setRequesterId] = useState("");
  const [derailData, setDerailData] = useState({});

  const [allMonths, setAllMonths] = useState([today]);

  auth.onAuthStateChanged(async (user) => {
    if (!user) {
      history.push("/login");
    } else {
      const userRef = firebase.firestore().doc(`users/${user.uid}`);
      const userDoc = await userRef.get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        if ("isAdmin" in userData) {
          if (userData.isAdmin) {
            if (!allowedSites) {
              getDerailData(user);
            }
            return;
          }
        }
      }
      history.push("/railserve");
    }
  });

  const getDerailData = async (user) => {
    const allowedSites = await getAllowedSites(user);
    setAllowedSites(allowedSites);

    fetchFromAPI(RequestTypes.UNITS, {}).then((derailResult) => {
      if (derailResult.success) {
        setDerailData(derailResult.data);

        fetchFromAPI(RequestTypes.SHADOW_CHANGE_LOG, {
          queryString: "site=usa",
        }).then((result) => {
          if (result.success) {
            let allMonths = [];
            let allMonthDateTimes = [];

            Object.keys(result.data).forEach((id) => {
              result.data[id].forEach((update) => {
                const updateDate = DateTime.fromFormat(
                  update.gmt,
                  "yyyy-MM-dd HH:mm:ss"
                );
                if (!allMonths.includes(updateDate.toFormat("LL/yyyy"))) {
                  allMonths.push(updateDate.toFormat("LL/yyyy"));
                  allMonthDateTimes.push(updateDate);
                }
              });
            });
            allMonthDateTimes.sort((a, b) => (a >= b ? -1 : 1));
            setAllMonths(allMonthDateTimes);
          }
        });
      }
    });
  };

  const selectedSiteChange = (e) => {
    setSelectedSite(e.target.value);
  };
  const selectedMonthChange = (e) => setSelectedMonth(e.target.value);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: getThemeColor(),
        height: "100vh",
        width: "100vw",
      }}
    >
      <div style={{ height: "8vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl
            style={{ color: "white" }}
            className={classes.formControl}
          >
            <InputLabel id="siteLabel" style={{ color: "white" }}>
              Site
            </InputLabel>
            <StyledSelect
              labelId="siteLabel"
              value={selectedSite}
              onChange={selectedSiteChange}
              style={{ color: "white" }}
            >
              {(allowedSites ?? []).map((site) => {
                return (
                  <MenuItem key={site} value={site}>
                    {formatSite(site)}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </FormControl>
          <FormControl
            style={{ color: "white" }}
            className={classes.formControl}
          >
            <InputLabel id="monthLabel" style={{ color: "white" }}>
              Month
            </InputLabel>
            <StyledSelect
              labelId="monthLabel"
              value={selectedMonth}
              onChange={selectedMonthChange}
              style={{ color: "white" }}
            >
              {(allMonths ?? []).map((date) => {
                return (
                  <MenuItem
                    key={date.toFormat("LL/yyyy")}
                    value={date.toFormat("LL/yyyy")}
                  >
                    {date.toFormat("LLLL yyyy")}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </FormControl>
          <TextField
            placeholder="Requester Id"
            variant="outlined"
            value={requesterId}
            onChange={(e) => setRequesterId(e.target.value)}
            inputProps={{
              style: { textAlign: "center", color: getThemeColor() },
            }}
            style={{
              width: "10vw",
              maxHeight: "64px",
              maxWidth: "400px",
              backgroundColor: "#FFFFFF",
              marginTop: "16px",
              borderRadius: "16px",
            }}
          />
          <BaseButton
            style={{ height: "3em", padding: "0 2em" }}
            onClick={() => {
              fetchFromAPI(RequestTypes.GENERATE_PDF, {
                queryParameters: {
                  site: selectedSite,
                  startDate: DateTime.fromFormat(
                    selectedMonth,
                    "LL/yyyy"
                  ).toISO(),
                  requesterId: requesterId,
                },
              }).then((result) => {
                if (result.success) {
                  setPdfBase64(result.data);
                } else {
                  console.log(result);
                }
              });
            }}
          >
            Submit
          </BaseButton>
        </div>
      </div>
      {pdfBase64 && (
        <iframe style={{ display: "flex", flexGrow: 1 }} src={pdfBase64} />
      )}
    </div>
  );
};
export default ReportPage;
