import React from "react";
import styled from "styled-components";
import {
  ListItemText,
  Collapse,
  List,
  ListItem,
  Checkbox,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import { getThemeColor } from "../../helpers/styleProvider";
import { formatSite, formatStatus } from "../../helpers/stringFormatter";
import DerailMenuIcon from "../../icons/derail/derail_menu.png";
import { getUnitStatusDerail } from "../../helpers/derailToInfo";
import { UnitTypes } from "../../enums/UnitTypes";
import DerailOnIcon from "../../icons/derail/FTS_Derail_on.png";
import DerailDisconnectedIcon from "../../icons/derail/FTS_Derail_disconnected.png";
import DerailOffIcon from "../../icons/derail/FTS_Derail_off.png";
import { DerailStatus } from "../../enums/UnitStatuses";
import { VIEW_LEVEL_USA } from "../../helpers/stringProvider";
import SideBarButton from "../common/SideBarButton";
import SideBarDeviceButton from "./SideBarDeviceButton";

const RightBar = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100vh;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 980px) {
    display: none;
  }
  @media (min-width: 981px) {
    min-width: 16vw;
    max-width: 16vw;
  }
  @media (min-width: 1201px) {
    min-width: 184px;
    max-width: 184px;
  }
`;

const RightBarStyled = styled.div`
  padding-bottom: 8vh;
  @media (max-width: 980px) {
    display: none;
  }
  @media (min-width: 981px) {
    padding-left: 2vw;
    min-width: 16vw;
    max-width: 16vw;
  }
  @media (min-width: 1201px) {
    padding-left: 24px;
    max-width: 196px;
    min-width: 196px;
  }
`;
const FilterListStyled = styled(List)`
  color: #fff;
  width: 85%;
`;

const FilterListItem = styled(ListItem)`
  && {
    @media (max-width: 980px) {
      width: 148px;
    }
    @media (min-width: 981px) {
      width: 15vw;
    }
    @media (min-width: 1201px) {
      width: 172px;
    }
  }
`;

const WhiteCheckbox = withStyles({
  root: {
    color: "#FFF",
    "&$checked": {
      color: "#FFF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class UnitMapRightSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDerailFilterOpen: false,
    };

    const currentHour = new Date().getHours();
    this.greeting =
      currentHour < 12
        ? "Good Morning"
        : currentHour < 18
        ? "Good Afternoon"
        : "Good Evening";

    this.derailFilterData = [];
    Object.values(DerailStatus).forEach((status) => {
      const formattedStatus = formatStatus(status);

      let filterIcon;
      switch (status) {
        case DerailStatus.DISCONNECTED:
          filterIcon = DerailDisconnectedIcon;
          break;
        case DerailStatus.DERAIL_OFF:
          filterIcon = DerailOffIcon;
          break;
        case DerailStatus.DERAIL_ON:
          filterIcon = DerailOnIcon;
          break;
      }
      if (props.filterStatus[UnitTypes.DERAIL][status] === undefined) {
        props.setFilterStatus(UnitTypes.DERAIL, status, true);
      }
      this.derailFilterData.push({
        status: status,
        filterTitle: formattedStatus,
        filterIcon: filterIcon,
      });
    });
  }

  render() {
    let siteDerailButtons = [];
    // TODO This should at minimum be put into a function
    // but if it could also only happen when unitsFound changes, it would save some calculations
    if (this.props.unitsFound && this.props.unitsFound[UnitTypes.DERAIL]) {
      siteDerailButtons = Object.values(
        this.props.unitsFound[UnitTypes.DERAIL]
      ).map((unit) => {
        const derailSite = unit.site;
        const unitStatus = getUnitStatusDerail(unit, unit.shadow);
        if (
          derailSite === this.props.viewLevel &&
          this.props.filterStatus[UnitTypes.DERAIL][unitStatus]
        ) {
          const unitSelected = async () => {
            this.props.setClickedUnitIdType(unit.id, UnitTypes.DERAIL, {
              shouldZoomOntoUnit: true,
            });
          };
          // TODO Convert these device centering buttons to components
          return (
            <div
              key={unit.id + "RightSelector"}
              style={{
                display: "flex",
                marginBottom: "8px",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <SideBarDeviceButton
                deviceName={unit.safeyardNickname ?? unit.thingName}
                onClick={unitSelected}
              />
            </div>
          );
        }
        return <React.Fragment key={unit.id} />;
      });
    }

    return (
      <RightBar>
        <RightBarStyled
          style={{
            backgroundColor: getThemeColor(),
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <div style={{ padding: "4vh 0", marginRight: "2vw" }}>
            <h4
              style={{
                color: "#FFF",
                margin: "0",
                padding: "0",
                textAlign: "right",
              }}
            >{`${this.greeting}${
              this.props.userData?.name ? `, ${this.props.userData?.name}` : ""
            }`}</h4>
          </div>

          <div
            style={{
              color: "#FFF",
              display: "flex",
              width: "100%",
              flexGrow: "1",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <h3
                style={{
                  color: "#FFF",
                  margin: "0px",
                  height: "auto",
                  paddingBottom: "8px",
                }}
              >
                {this.props.viewLevel === VIEW_LEVEL_USA
                  ? "Devices"
                  : formatSite(this.props.viewLevel) + " Devices"}
              </h3>
              <div
                className="scrollbar"
                style={{
                  display: "flex",
                  height: "40vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      transition: "width 500ms ease",
                      width: "16vw",
                      paddingLeft: "0px",
                      paddingRight: "4px",
                      height:
                        this.props.viewLevel === VIEW_LEVEL_USA
                          ? "auto"
                          : "0px",
                      opacity:
                        this.props.viewLevel === VIEW_LEVEL_USA ? "100%" : "0%",
                    }}
                  >
                    <p style={{ textAlign: "left", fontSize: "16px" }}>
                      <i>Please select a site to view relevant devices</i>
                    </p>
                  </div>
                </div>
                {siteDerailButtons}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <h3 style={{ color: "#FFF", marginTop: "4vh" }}>Filters</h3>
              <FilterListStyled disablePadding style={{ paddingRight: "4px" }}>
                <FilterListItem
                  button
                  onClick={() => {
                    this.setState({
                      isDerailFilterOpen: !this.state.isDerailFilterOpen,
                    });
                  }}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <img
                    src={DerailMenuIcon}
                    style={{ height: "24px" }}
                    alt="Derail"
                  />
                  <ListItemText
                    primary="Derails"
                    style={{ paddingLeft: "8px" }}
                  />
                  {this.state.isDerailFilterOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </FilterListItem>
                <Collapse
                  in={this.state.isDerailFilterOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {this.derailFilterData.map((filterData) => {
                      return (
                        <FilterListItem
                          key={filterData.filterTitle}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <img
                            src={filterData.filterIcon}
                            style={{ height: "24px" }}
                            alt=""
                          />
                          <ListItemText
                            primary={filterData.filterTitle}
                            style={{ paddingLeft: "8px" }}
                          />
                          <WhiteCheckbox
                            style={{ zIndex: "1" }}
                            checked={
                              this.props.filterStatus[UnitTypes.DERAIL][
                                filterData.status
                              ]
                            }
                            onChange={(e) => {
                              this.props.setFilterStatus(
                                UnitTypes.DERAIL,
                                filterData.status,
                                e.target.checked
                              );
                            }}
                          />
                        </FilterListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </FilterListStyled>
            </div>

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <SideBarButton
                text={"Logout"}
                onClick={() => this.props.userLogout()}
              />
            </div>
          </div>
        </RightBarStyled>
      </RightBar>
    );
  }
}

export default UnitMapRightSidebar;
