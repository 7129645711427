import React from "react";
import BaseButton from "./BaseButton";

const SelectableButton = ({ isSelected, style, onClick, text }) => {
  return (
    <BaseButton
      style={{
        boxShadow: isSelected ? "" : "0 0 0 2pt white",
        color: isSelected ? "#112337" : "#FFF",
        backgroundColor: isSelected ? "#FFF" : "#112337",
        ...style,
      }}
      onClick={onClick}
    >
      {text}
    </BaseButton>
  );
};

export default SelectableButton;
