import React from "react";
import { Popup } from "react-mapbox-gl";

import { UnitTypes } from "../../enums/UnitTypes";
import { formatStatus, formatThingName } from "../../helpers/stringFormatter";
import { getUnitStatusDerail } from "../../helpers/derailToInfo";

class HoveredPopup extends React.PureComponent {
  render() {
    if (this.props.unitId) {
      const unit =
        this.props.unitsFound[this.props.unitType][this.props.unitId];
      if (unit && this.props.unitType === UnitTypes.DERAIL) {
        const formattedThingName = formatThingName(unit.thingName);
        const coordinates = unit.coordinates;
        if (!coordinates) {
          return <div />;
        }
        const shadow = unit.shadow ?? {};
        const unitStatus = getUnitStatusDerail(unit, shadow);
        const batteryFormat = shadow.bat ? `${shadow.bat} mV` : "Not reported";
        const subtitle = (
          <div>
            <strong>Status:</strong> {formatStatus(unitStatus)}
            <br />
            <strong>Battery Level:</strong> {batteryFormat}
            {this.props.isClickable && (
              <React.Fragment>
                <br />
                <p>
                  <i>Click for more details</i>
                </p>
              </React.Fragment>
            )}
          </div>
        );
        return (
          <Popup
            coordinates={coordinates}
            offset={{
              "bottom-left": [0, 0],
              bottom: [0, 0],
              "bottom-right": [0, 0],
            }}
          >
            <h1>{unit.safeyardNickname ?? formattedThingName}</h1>
            <div>{subtitle}</div>
          </Popup>
        );
      } else {
        // TODO Handle other unit types
      }
    }
    return <div />;
  }
}

export default HoveredPopup;
