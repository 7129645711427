export const RequestTypes = {
  UNITS: "units",
  UNIT: "unit",
  UNIT_SHADOW: "unit-shadow",
  SUBSCRIBE: "subscribe",
  UNSUBSCRIBE: "unsubscribe",
  UNIT_SUBSCRIPTIONS: "get-subscriptions",
  SHADOW_CHANGE_LOG: "shadow-change-log",
  GENERATE_PDF: "generate-pdf",
  REQUEST_REPORT: "request-report-email",
  PULL_NEW_DEVICE: "pull-new-device",
  GET_DEVICE_ID: "get-device-id",
  REFRESH_BACKEND: "refresh-backend",
  ADD_UIDS_TO_DEVICE_NOTIFICATIONS: "add-uids-to-device-notifications",
  REMOVE_UIDS_FROM_DEVICE_NOTIFICATIONS:
    "remove-uids-from-device-notifications",
  CREATE_USER: "create-user",
};

export default RequestTypes;
