import React from "react";
import Button from "@material-ui/core/Button";

import { getThemeColor } from "../../helpers/styleProvider";

// To make sidebar transitions smooth, these components need to always exist in the DOM or the transitions spike open
const SideDeviceBarButton = ({ onClick, isPressed, deviceName }) => (
  <>
    <Button
      variant="outlined"
      color="secondary"
      onClick={onClick}
      style={{
        // Can't use props and styled component for some of these since Material overrides them otherwise
        minWidth: "120px",
        color: isPressed ? getThemeColor() : "#FFF",
        opacity: "100%",
        overflow: "hidden",
        maxWidth: "160px",
        width: "14vw",
      }}
    >
      <b>{deviceName}</b>
    </Button>
  </>
);
export default SideDeviceBarButton;
