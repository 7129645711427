import React from "react";
import ReactMapboxGl, { Source, Popup } from "react-mapbox-gl";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import {
  Button,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import HoveredPopup from "../common/HoveredPopup";
import ClickedPopup from "../common/ClickedPopup";
import { getUnitStatusDerail } from "../../helpers/derailToInfo";
import { unitTypeToStatuses } from "../../enums/UnitStatuses";
import { UnitTypes } from "../../enums/UnitTypes";
import { siteToZoom } from "../../helpers/siteHelper";
import { createLayerComponent } from "../../helpers/unitStyleHelper";
import { formatSite } from "../../helpers/stringFormatter";
import { getThemeColor } from "../../helpers/styleProvider";
import "../../css/scroll.css";
import CompassIcon from "../../icons/north_compass.png";
import MapSitesOverlay from "../common/MapSitesOverlay";
import { VIEW_LEVEL_USA } from "../../helpers/stringProvider";

const RightTopCompass = styled.div`
  position: fixed;
  height: 6vh;
  display: flex;
  @media (max-width: 980px) {
    display: none;
  }
  @media (min-width: 981px) {
    right: calc(16vw + 36px);
    top: 16px;
  }
  @media (min-width: 1201px) {
    right: 232px;
    top: 16px;
  }
`;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoidHlsZXJtY2RvbmFsZCIsImEiOiJja2locXB2MWgwNmh0MndtbzgwZnh5cWJ4In0.w-Mvtis4wJCm22WECsrZWQ",
  interactive: true,
  dragRotate: false,
  pitchWithRotate: false,
  touchZoomRotate: true,
  attributionControl: false,
  doubleClickZoom: false,
});

class UnitMap extends React.Component {
  constructor(props) {
    super(props);

    this.GPS_THRESHOLD = 0.00001;

    this.RETURN_TO_SITE_COORD_THRESHOLD = 0.01;
    this.RETURN_TO_SITE_ZOOM_THRESHOLD = 2;

    this.ENTERED_SITE_COORD_THRESHOLD = 0.01;
    this.ENTERED_SITE_ZOOM_THRESHOLD = 1.5;

    this.width = props.width || "800px";
    this.height = props.height || "800px";
    this.timeoutID = undefined;

    this.calculateSourcesAndLayersDerail =
      this.calculateSourcesAndLayersDerail.bind(this);

    this.calculateSelectedSiteDerails =
      this.calculateSelectedSiteDerails.bind(this);

    this.setMapViewLevel = this.setMapViewLevel.bind(this);

    this.checkIfUserEnteredSite = this.checkIfUserEnteredSite.bind(this);

    this.USA_VIEW_LEVEL_DATA = {
      zoom: isMobile ? [3.2] : [4.2],
      center: [-95.7129, 37.0902],
    };

    this.state = {
      bearing: [0],
      unitToLatLongMap: {},
      hoveredUnitId: null,
      hoveredUnitType: null,
      selectedSiteDerails: [],
      isUnsubscribedSnackbarVisible: false,
      isSubscribedSnackbarVisible: false,
      isReturnToSiteSnackbarVisible: false,
      hoveredSite: null,
    };

    this.state.center =
      (props.allowedSitesData[props.viewLevel] ?? {}).center ??
      this.USA_VIEW_LEVEL_DATA.center;
    this.state.zoom = this.USA_VIEW_LEVEL_DATA.zoom;
    if (props.viewLevel !== VIEW_LEVEL_USA && props.allowedSitesData) {
      this.state.zoom = siteToZoom(props.viewLevel, props.allowedSitesData, {
        isMobile: isMobile,
      });
    }
  }

  calculateSelectedSiteDerails(site) {
    let selectedSiteDerails = [];
    let allDerails = this.props.unitsFound[UnitTypes.DERAIL] ?? {};

    Object.keys(allDerails).forEach((unitId) => {
      const derailSite = allDerails[unitId].site;
      if (derailSite === site) {
        selectedSiteDerails.push(unitId);
      }
    });
    this.setState({ selectedSiteDerails: selectedSiteDerails });
  }

  setMapViewLevel(newViewLevel) {
    let newMapData;
    if (newViewLevel === VIEW_LEVEL_USA) {
      newMapData = this.USA_VIEW_LEVEL_DATA;
    } else {
      const siteData = this.props.allowedSitesData[newViewLevel];
      if (!siteData) {
        return;
      }
      newMapData = {
        zoom: isMobile ? siteData.mobileZoom : siteData.webZoom,
        center: siteData.center,
      };
    }

    this.map.flyTo(newMapData);
    if (this.props.viewLevel !== newViewLevel) {
      this.calculateSelectedSiteDerails(newViewLevel);
      localStorage.setItem("viewLevel", newViewLevel);
    }
  }

  checkIfUserEnteredSite() {
    for (const site of Object.keys(this.props.allowedSitesData)) {
      const siteData = this.props.allowedSitesData[site];
      const siteCenter = siteData.center;
      const currMapCenter = [
        this.map.getCenter().lng,
        this.map.getCenter().lat,
      ];
      const offRadius = Math.pow(
        Math.pow(siteCenter[0] - currMapCenter[0], 2) +
          Math.pow(siteCenter[1] - currMapCenter[1], 2),
        0.5
      );
      if (offRadius <= this.ENTERED_SITE_COORD_THRESHOLD) {
        const siteZoom = isMobile ? siteData.mobileZoom : siteData.webZoom;
        if (
          Math.abs(siteZoom - this.map.getZoom()) <=
          this.ENTERED_SITE_ZOOM_THRESHOLD
        ) {
          return site;
        }
      }
    }
    return null;
  }

  onStyleLoad = (m) => {
    this.map = m;

    this.map.on("dragend", (e) => {
      if (this.props.viewLevel !== VIEW_LEVEL_USA) {
        const siteCenter =
          this.props.allowedSitesData[this.props.viewLevel].center;
        const newMapCenter = [
          this.map.getCenter().lng,
          this.map.getCenter().lat,
        ];
        const offRadius = Math.pow(
          Math.pow(siteCenter[0] - newMapCenter[0], 2) +
            Math.pow(siteCenter[1] - newMapCenter[1], 2),
          0.5
        );
        if (offRadius >= this.RETURN_TO_SITE_COORD_THRESHOLD) {
          const enteredSite = this.checkIfUserEnteredSite();
          if (enteredSite && enteredSite !== this.props.viewLevel) {
            this.props.setViewLevel(enteredSite);
          } else {
            this.setState({ isReturnToSiteSnackbarVisible: true });
          }
        } else {
          this.setState({ isReturnToSiteSnackbarVisible: false });
        }
      } else {
        const enteredSite = this.checkIfUserEnteredSite();
        if (enteredSite) {
          this.props.setViewLevel(enteredSite);
        }
      }
    });

    this.map.on("zoomend", (e) => {
      if ("originalEvent" in e) {
        if (this.props.viewLevel !== VIEW_LEVEL_USA) {
          const siteZoom = siteToZoom(
            this.props.viewLevel,
            this.props.allowedSitesData,
            { isMobile: isMobile }
          );
          const enteredSite = this.checkIfUserEnteredSite();
          if (!enteredSite) {
            this.setState({ isReturnToSiteSnackbarVisible: true });
          } else if (enteredSite !== this.props.viewLevel) {
            this.props.setViewLevel(enteredSite);
          } else {
            if (
              siteZoom - this.map.getZoom() >=
              this.RETURN_TO_SITE_ZOOM_THRESHOLD
            ) {
              this.setState({ isReturnToSiteSnackbarVisible: true });
            } else {
              this.setState({ isReturnToSiteSnackbarVisible: false });
            }
          }
        } else {
          const enteredSite = this.checkIfUserEnteredSite();
          if (enteredSite && enteredSite !== this.props.viewLevel) {
            this.props.setViewLevel(enteredSite);
          }
        }
      } else {
        this.setState({ isReturnToSiteSnackbarVisible: false });
      }
    });

    this.map.on("click", (e) => {
      const feature = this.map.queryRenderedFeatures(e.point)[0];
      if (
        feature &&
        feature.properties &&
        feature.properties.unitId &&
        feature.properties.unitType
      ) {
        const clickedUnitId = feature.properties.unitId;
        const clickedUnitType = feature.properties.unitType;
        if (this.props.clickedUnitId === clickedUnitId) {
          this.props.setClickedUnitIdType(null, null, {});
        } else {
          this.props.setClickedUnitIdType(clickedUnitId, clickedUnitType, {});
        }
      } else {
        this.props.setClickedUnitIdType(null, null, {});
      }
    });

    this.map.on("mousemove", (e) => {
      if (!isMobile) {
        const feature = this.map.queryRenderedFeatures(e.point)[0];
        if (
          feature &&
          feature.properties &&
          feature.properties.unitId &&
          feature.properties.unitType
        ) {
          const hoveredUnitId = feature.properties.unitId;
          const hoveredUnitType = feature.properties.unitType;

          if (hoveredUnitId !== this.state.hoveredUnitId) {
            this.setState({
              hoveredUnitId: hoveredUnitId,
              hoveredUnitType: hoveredUnitType,
            });
          }
        } else if (!feature) {
          this.setState({ hoveredUnitId: null, hoveredUnitType: null });
        }
      }
    });
    let pointLayers = [];
    Object.values(UnitTypes).forEach((type) => {
      const statuses = unitTypeToStatuses(type);
      Object.values(statuses).forEach((status) => {
        const layerTitle = type + status + "Layer";
        pointLayers.push(layerTitle);
      });
    });

    this.map.on("mouseenter", "siteCentersLayer", (e) => {
      if (!isMobile) {
        if (e.features.length > 0) {
          if (e.features[0].properties && e.features[0].properties.siteName) {
            const site = formatSite(e.features[0].properties.siteName);
            const coordinates =
              this.props.allowedSitesData[e.features[0].properties.siteName]
                .center;
            this.setState({
              hoveredSite: site,
              hoveredSiteCoordinates: coordinates,
            });
          }
        }
      }
    });

    this.map.on("mouseleave", "siteCentersLayer", (e) => {
      this.setState({ hoveredSite: null, hoveredSiteCoordinates: null });
    });

    this.map.on("click", "siteCentersLayer", (e) => {
      if (e.features.length > 0) {
        if (e.features[0].properties && e.features[0].properties.viewLevel) {
          this.props.setViewLevel(e.features[0].properties.viewLevel);
          this.setState({ hoveredSite: null, hoveredSiteCoordinates: null });
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.viewLevel !== prevProps.viewLevel) {
      this.setMapViewLevel(this.props.viewLevel);
    }
    // Meant to refresh map location if user presses same view level again
    else if (this.props.viewLevelUpdater !== prevProps.viewLevelUpdater) {
      this.setMapViewLevel(this.props.viewLevel);
    }

    // Meant to update map location if user presses sidebar device button, but not clicking on device
    if (
      this.props.clickedUnitId &&
      this.props.clickedUnitId !== prevProps.clickedUnitId &&
      this.props.zoomUpdater !== prevProps.zoomUpdater
    ) {
      const unit =
        this.props.unitsFound[UnitTypes.DERAIL][this.props.clickedUnitId] ?? {};
      if (unit.coordinates) {
        this.map.flyTo({ center: unit.coordinates, zoom: [17] });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  shouldComponentUpdate() {
    return true;
  }

  calculateSourcesAndLayersDerail() {
    let statusToFeatureListMap = {};
    if ((this.props.unitsFound ?? {})[UnitTypes.DERAIL]) {
      Object.keys(this.props.unitsFound[UnitTypes.DERAIL]).forEach((id) => {
        const unit = this.props.unitsFound[UnitTypes.DERAIL][id];

        const shadow = this.props.unitsFound[UnitTypes.DERAIL][id].shadow;
        const unitStatus = getUnitStatusDerail(unit, shadow);

        if (unit && unit.coordinates) {
          const mapFeature = {
            type: "Feature",
            geometry: { type: "Point", coordinates: unit.coordinates },
            properties: { unitId: id, unitType: UnitTypes.DERAIL },
          };

          let newFeatureList = statusToFeatureListMap[unitStatus] ?? [];

          newFeatureList.push(mapFeature);
          statusToFeatureListMap[unitStatus] = newFeatureList;
        }
      });
    }
    return statusToFeatureListMap;
  }

  render() {
    let sourceList = [];
    let layerList = [];
    Object.values(UnitTypes).forEach((type) => {
      let sourcesAndLayers;
      switch (type) {
        case UnitTypes.DERAIL:
          sourcesAndLayers = this.calculateSourcesAndLayersDerail();
          break;
        default:
          return;
      }
      const statuses = unitTypeToStatuses(type);
      Object.values(statuses).forEach((status) => {
        const sourceTitle = type + status + "Source";
        const newSource = (
          <Source
            id={sourceTitle}
            key={sourceTitle}
            geoJsonSource={{
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: sourcesAndLayers[status] ?? [],
              },
            }}
          />
        );
        sourceList.push(newSource);

        const layerTitle = type + status + "Layer";
        const newLayer = createLayerComponent(
          type,
          status,
          sourceTitle,
          layerTitle,
          this.props.filterStatus
        );
        layerList.push(newLayer);
      });
    });
    let hoveredPopup = <div />;
    if (
      this.state.hoveredUnitId &&
      this.state.hoveredUnitId !== this.props.clickedUnitId
    ) {
      hoveredPopup = (
        <HoveredPopup
          key={this.state.hoveredUnitId}
          unitId={this.state.hoveredUnitId}
          unitType={this.state.hoveredUnitType}
          unitsFound={this.props.unitsFound ?? {}}
          isClickable={true}
        />
      );
    }

    let clickedPopup = <div />;
    if (this.props.clickedUnitId) {
      clickedPopup = (
        <ClickedPopup
          key={this.props.clickedUnitId}
          onDerailNotificationSettingChange={
            this.props.onDerailNotificationSettingChange
          }
          userData={this.props.userData}
          canUserChangeNotifications={this.props.canUserChangeNotifications}
          unitId={this.props.clickedUnitId}
          unitType={this.props.clickedUnitType}
          unitsFound={this.props.unitsFound ?? {}}
          updateUnitNickname={(id, newNickname) => {
            this.props.updateUnitsFoundNickname(id, newNickname);
          }}
          openChangeNickname={this.props.openChangeNickname}
        />
      );
    }
    let sitePopup = <div />;
    if (this.state.hoveredSite) {
      sitePopup = (
        <Popup
          coordinates={this.state.hoveredSiteCoordinates}
          key={this.state.hoveredSite}
        >
          <h1>{this.state.hoveredSite}</h1>
        </Popup>
      );
    }

    return (
      <React.Fragment>
        <Map
          style="mapbox://styles/tylermcdonald/ckolq4qbb0ch917q6eq008xsk"
          containerStyle={{ height: this.height, width: this.width }}
          center={this.state.center}
          zoom={this.state.zoom}
          bearing={this.state.bearing}
          onStyleLoad={this.onStyleLoad}
          flyToOptions={{
            speed: 1.6,
          }}
        >
          <MapSitesOverlay sitesData={this.props.allowedSitesData} />
          {sourceList}
          {layerList}
          {hoveredPopup}
          {clickedPopup}
          {sitePopup}
        </Map>
        {!isMobile && (
          <RightTopCompass>
            <img src={CompassIcon} style={{ height: "6vh" }} alt="Compass" />
          </RightTopCompass>
        )}
        <Snackbar
          open={this.state.isReturnToSiteSnackbarVisible}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={() => {
            this.setState({ isReturnToSiteSnackbarVisible: false });
          }}
        >
          <SnackbarContent
            style={{
              backgroundColor: getThemeColor(),
            }}
            message={`Return to ${formatSite(this.props.viewLevel)} yard`}
            action={
              <React.Fragment>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => this.props.setViewLevel(this.props.viewLevel)}
                >
                  Return
                </Button>
              </React.Fragment>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.isSubscribedSnackbarVisible}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({ isSubscribedSnackbarVisible: false });
          }}
          message="Subscribed successfully!"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  this.setState({ isSubscribedSnackbarVisible: false });
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.isUnsubscribedSnackbarVisible}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({ isUnsubscribedSnackbarVisible: false });
          }}
          message="Unsubscribed successfully!"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  this.setState({ isUnsubscribedSnackbarVisible: false });
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(UnitMap);
