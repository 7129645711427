import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import React from "react";
import styled from "styled-components";
import { getThemeColor } from "../../helpers/styleProvider";

const SlidingMenuStyled = styled.div`
  position: absolute;
  width: 100vw;
  z-index: 2;
  bottom: 0;

  @media (min-width: 981px) {
    display: none;
  }

  transition: height 500ms ease;
  -moz-transition: height 500ms ease;
  -ms-transition: height 500ms ease;
  -o-transition: height 500ms ease;
  -webkit-transition: height 500ms ease;
`;

const SlidingMenu = ({ isOpen, setIsOpen, children }) => (
  <SlidingMenuStyled
    style={{
      backgroundColor: getThemeColor(),
      height: isOpen ? "60vh" : "8vh",
    }}
  >
    <div
      style={{
        display: "flex",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
        padding: "0 0 0 0",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <IconButton
          style={{ outline: "none", padding: "0 0 5% 0" }}
          onClick={setIsOpen}
        >
          {isOpen ? (
            <ExpandMoreIcon style={{ color: "#FFF", fontSize: 48 }} />
          ) : (
            <ExpandLessIcon style={{ color: "#FFF", fontSize: 48 }} />
          )}
        </IconButton>
      </div>
      {children}
    </div>
  </SlidingMenuStyled>
);

export default SlidingMenu;
