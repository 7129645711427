import firebase from "../../firebase";

const shouldLogFunctions = false;

export async function getAllowedSites(user) {
  if (!user) {
    return [];
  }
  if (shouldLogFunctions) {
    console.log("getAllowedSites");
  }
  // Check if user is admin
  const userDoc = await firebase.firestore().doc(`users/${user.uid}`).get();
  if (userDoc.exists) {
    if (userDoc.data().isAdmin) {
      let allSites = [];
      (await firebase.firestore().collection(`site_metadata`).get()).forEach(
        (doc) => allSites.push(doc.id)
      );
      return allSites;
    }
  }

  // If user is not admin, get all site documents that user is permitted
  const userSitePermissionsRef = firebase
    .firestore()
    .collection(`users/${user.uid}/site_permissions`);
  const permissionsSnapshot = await userSitePermissionsRef
    .where("permissionLevel", ">=", 1)
    .get();
  if (permissionsSnapshot.empty) {
    return [];
  }
  const allowedSites = [];
  permissionsSnapshot.forEach((siteDoc) => {
    allowedSites.push(siteDoc.id);
  });
  return allowedSites;
}

export async function getSiteInformation(user, site) {
  if (shouldLogFunctions) {
    console.log("getSiteInformation");
  }
  if (!user || !site) {
    return {};
  }

  const siteMetadataRef = firebase.firestore().doc(`site_metadata/${site}`);
  const siteMetadataDoc = await siteMetadataRef.get();
  if (siteMetadataDoc.exists) {
    let metadata = siteMetadataDoc.data();
    metadata.site = site;
    return metadata;
  }
  return {};
}

export async function getAllowedSitesData(user, { onSiteData, firstSite }) {
  if (shouldLogFunctions) {
    console.log("getAllowedSitesData");
  }
  if (!user) {
    return {};
  }
  const allowedSites = await getAllowedSites(user);

  let allowedSitesData = {};
  // Allow for preference in first site loaded. Used for when the user has a stored last view level
  if (firstSite && allowedSites.includes(firstSite)) {
    const siteData = await getSiteInformation(user, firstSite);
    allowedSitesData[firstSite] = siteData;
    if (onSiteData) {
      onSiteData(firstSite, siteData);
    }
    allowedSites.splice(allowedSites.indexOf(firstSite), 1);
  }
  for (const site of allowedSites) {
    const siteData = await getSiteInformation(user, site);
    allowedSitesData[site] = siteData;
    if (onSiteData) {
      onSiteData(site, siteData);
    }
  }
  return allowedSitesData;
}

// Don't need user, if firebase rules are implemented properly
export async function getUnitData(unitId) {
  if (shouldLogFunctions) {
    console.log("getUnitData");
  }
  if (!unitId) {
    return {};
  }
  try {
    const unitMetadataDoc = await firebase
      .firestore()
      .doc(`device_metadata/${unitId}`)
      .get();
    if (unitMetadataDoc.exists) {
      return unitMetadataDoc.data();
    }
  } catch (e) {
    console.log(e);
    return {};
  }
  return {};
}

export async function updateUnitNickname(unitId, newNickname) {
  if (shouldLogFunctions) {
    console.log("updateUnitNickname");
  }
  if (!unitId || !newNickname || newNickname === "") {
    return false;
  }
  const unitMetadataRef = firebase.firestore().doc(`device_metadata/${unitId}`);
  try {
    await unitMetadataRef.update({
      safeyardNickname: newNickname,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
  return true;
}

// 0: Success
// 1: Unit with id already exists.
// 2: Firebase error
// 3: Invalid unit id
// 4: Insufficient permissions
export async function createNewDevice(unitId, unitMetadata) {
  if (shouldLogFunctions) {
    console.log("createNewDevice");
  }
  if (!unitId) {
    return 3;
  }
  const unitMetadataRef = firebase.firestore().doc(`device_metadata/${unitId}`);
  let unitMetadataDoc;
  try {
    unitMetadataDoc = await unitMetadataRef.get();
  } catch (e) {
    console.log(e);
    return 4;
  }
  if (
    unitMetadataDoc.exists &&
    unitMetadataDoc.data().lat &&
    unitMetadataDoc.data().lng &&
    unitMetadataDoc.data().site
  ) {
    console.log(`Unit with id ${unitId} already exists in firebase.`);
    return 1;
  }

  unitMetadata.id = unitId;
  try {
    await unitMetadataRef.set(unitMetadata);
    return 0;
  } catch (e) {
    console.log(e);
    return 2;
  }
}

export async function updateNewDevice(unitId, unitMetadata) {
  if (shouldLogFunctions) {
    console.log("updateNewDevice");
  }
  if (!unitId) {
    return 3;
  }
  const unitMetadataRef = firebase.firestore().doc(`device_metadata/${unitId}`);
  const unitMetadataDoc = await unitMetadataRef.get();
  if (!unitMetadataDoc.exists) {
    console.log(`Tried updating unit that doesn't exist. Use createNewDevice.`);
    return 1;
  }

  unitMetadata.id = unitId;
  try {
    await unitMetadataRef.set({});
    await unitMetadataRef.update(unitMetadata);
    return 0;
  } catch (e) {
    console.log(e);
    return 2;
  }
}

export async function deleteDevice(unitId) {
  if (shouldLogFunctions) {
    console.log("deleteDevice");
  }
  try {
    await firebase.firestore().doc(`device_metadata/${unitId}`).delete();
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeDevice(unitId) {
  if (shouldLogFunctions) {
    console.log("deleteDevice");
  }
  try {
    await firebase.firestore().doc(`device_metadata/${unitId}`).update({
      lat: firebase.firestore.FieldValue.delete(),
      lng: firebase.firestore.FieldValue.delete(),
      site: firebase.firestore.FieldValue.delete(),
      safeyardNickname: firebase.firestore.FieldValue.delete(),
      addedNotificationUids: firebase.firestore.FieldValue.delete(),
      isRegionalLeaderNotificationsEnabled:
        firebase.firestore.FieldValue.delete(),
      isSeniorSiteLeaderNotificationsEnabled:
        firebase.firestore.FieldValue.delete(),
      isSiteLeaderNotificationsEnabled: firebase.firestore.FieldValue.delete(),
      toRefresh: true,
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getUserData(user) {
  if (shouldLogFunctions) {
    console.log("getUserData");
  }
  const userDoc = await firebase.firestore().doc(`users/${user.uid}`).get();
  if (!userDoc.exists) {
    return {};
  }
  return { ...userDoc.data(), id: user.uid };
}

export async function updateNotificationSetting(
  unitId,
  newValue,
  {
    isSiteLeader = false,
    isSeniorSiteLeader = false,
    isRegionalLeader = false,
    otherUids = [],
  }
) {
  const updateData = {};
  if (shouldLogFunctions) {
    console.log("updateNotificationSetting");
  }

  if (isSiteLeader) {
    updateData.isSiteLeaderNotificationsEnabled = newValue;
  }
  if (isSeniorSiteLeader) {
    updateData.isSeniorSiteLeaderNotificationsEnabled = newValue;
  }
  if (isRegionalLeader) {
    updateData.isRegionalLeaderNotificationsEnabled = newValue;
  }
  if (otherUids.length !== 0) {
    // Add uids to notification list
    if (newValue) {
      updateData.addedNotificationUids =
        firebase.firestore.FieldValue.arrayUnion(...otherUids);
    } else {
      // Remove uids from notification list
      updateData.addedNotificationUids =
        firebase.firestore.FieldValue.arrayRemove(...otherUids);
    }
  }

  await firebase
    .firestore()
    .doc(`device_metadata/${unitId}`)
    .update(updateData);
}
