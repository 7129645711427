import styled from "styled-components";

/** Basic button to be used for most triggers */
export default styled.button`
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  margin: 8px 0 8px 0;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 0 0 2pt white;
  color: #fff;
  background-color: #112337;
  overflow: hidden;
  position: static;
  cursor: pointer;
`;
