import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";

const ChangeNicknameDialog = ({
  unitsFound,
  unitType,
  unitId,
  isDialogOpen,
  onClose,
  onSubmit,
}) => {
  const unit = unitsFound[unitType][unitId] ?? {};
  const [nickname, setNickname] = useState(
    unit.safeyardNickname ?? unit.thingName ?? ""
  );

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Change Nickname</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Type the new nickname for this device.
        </DialogContentText>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ width: "12vw" }}>
              <TextField
                label="Nickname"
                value={nickname}
                onChange={(e) => {
                  if (e.target.value.trim().length !== 0) {
                    setNickname(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(unitId, nickname);
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChangeNicknameDialog;
