import React, { useState, useMemo } from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";

import { getThemeColor } from "../helpers/styleProvider";

const LoginContainer = styled.div`
  @media (max-width: 820px) {
    padding-top: 8vh;
  }
  padding-top: 0vh;
  display: flex;
  flex-direction: column;
`;

const LoginPage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);

  let history = useHistory();

  const auth = useMemo(() => firebase.auth());
  auth.onAuthStateChanged((user) => {
    if (user) {
      history.push("/railserve");
    }
  });

  const performSignIn = () => {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      auth
        .signInWithEmailAndPassword(userEmail.trim(), userPassword)
        .then((userCredential) => {
          history.push("/railserve");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/wrong-password":
              setLoginError("Invalid password for this email.");
              break;
            case "auth/user-not-found":
              setLoginError("No account is associated with this email.");
              break;
            case "auth/invalid-email":
              setLoginError("Invalid email.");
              break;
            default:
              setLoginError("Server error. Try again later.");
              console.log(error.code);
              break;
          }
        });
    });
  };

  const showResetPassword = () => setIsResetPassword(true);
  const showLogin = () => setIsResetPassword(false);

  const performResetPassword = async () => {
    firebase
      .auth()
      .sendPasswordResetEmail(userEmail.trim())
      .then(() => {
        // Password reset email sent!
        setResetPasswordSent(true);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: getThemeColor(),
        height: "100vh",
        width: "100vw",
      }}
    >
      <div style={{ height: "8vh", marginTop: "1vh", paddingLeft: "8vw" }}>
        <h1 style={{ color: "#FFF" }}>Safe Yard</h1>
      </div>
      <LoginContainer>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            fontFamily: "'Times New Roman', serif",
          }}
        >
          <h1 style={{ color: "#FFF" }}>
            {isResetPassword ? "Reset Password" : "Login"}
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "400px",
            marginLeft: "auto",
            marginRight: "auto",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            {isResetPassword ? (
              <p style={{ color: "#FFF" }}>
                Enter the <b>email</b> to reset the password for
              </p>
            ) : (
              <p style={{ color: "#FFF" }}>
                Enter the <b>email</b> and <b>password</b> you received from
                Focused Technology Solutions
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "400px",
              width: "80vw",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Email"
              variant="outlined"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              inputProps={{
                style: { textAlign: "center", color: getThemeColor() },
              }}
              style={{
                width: "80%",
                maxWidth: "400px",
                backgroundColor: "#FFFFFF",
                marginTop: "16px",
                borderRadius: "16px",
              }}
            />
            {!isResetPassword && (
              <TextField
                placeholder="Password"
                variant="outlined"
                value={userPassword}
                type="password"
                onChange={(e) => setUserPassword(e.target.value)}
                inputProps={{
                  style: { textAlign: "center", color: getThemeColor() },
                }}
                style={{
                  width: "80%",
                  maxWidth: "400px",
                  backgroundColor: "#FFFFFF",
                  marginTop: "16px",
                  borderRadius: "16px",
                }}
              />
            )}
            <div style={{ height: "1em" }} />
            {isResetPassword ? (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={performResetPassword}
                  style={{
                    width: "70%",
                    height: "48px",
                    boxShadow: "0 0 0 2pt white",
                  }}
                >
                  Reset Password
                </Button>
                {resetPasswordSent && (
                  <p
                    style={{
                      color: "white",
                      margin: ".5em, 0",
                      padding: "0",
                      width: "70%",
                      textAlign: "center",
                    }}
                  >
                    If an account with that email exists, a reset password email
                    has been sent.
                  </p>
                )}
                <div style={{ height: "1em" }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={showLogin}
                  style={{
                    width: "70%",
                    height: "48px",
                    boxShadow: "0 0 0 2pt white",
                  }}
                >
                  Login
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={performSignIn}
                  style={{
                    width: "70%",
                    height: "48px",
                    boxShadow: "0 0 0 2pt white",
                  }}
                >
                  Login
                </Button>
                {loginError !== "" && (
                  <p style={{ color: "red" }}>{loginError}</p>
                )}
                <div style={{ height: "1em" }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={showResetPassword}
                  style={{
                    width: "70%",
                    height: "48px",
                    boxShadow: "0 0 0 2pt white",
                  }}
                >
                  Reset Password
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </LoginContainer>
    </div>
  );
};
export default LoginPage;
