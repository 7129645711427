import React from "react";

import SelectableButton from "./SelectableButton";

// To make sidebar transitions smooth, these components need to always exist in the DOM or the transitions spike open
const SideBarButton = ({ isPressed, onClick, text }) => {
  return (
    <SelectableButton
      isSelected={isPressed}
      onClick={onClick}
      text={text}
      style={{
        minWidth: "120px",
        margin: "8px 0 8px 0",
        padding: "4px",
        borderRadius: "8px",
        opacity: "100%",
        overflow: "hidden",
        position: "static",
        maxWidth: "160px",
        width: "14vw",
        minHeight: "48px",
      }}
    />
  );
};
export default SideBarButton;
