import Ajv from "ajv";
import addFormats from "ajv-formats";

const ajv = new Ajv();
addFormats(ajv);

const newSiteSchema = {
  type: "object",
  properties: {
    address: { type: "string" },
    lat: { type: "number" },
    lng: { type: "number" },
    mobileZoom: { type: "number" },
    webZoom: { type: "number" },
    officePhoneNumber: { type: "string" },
    regionalManagerEmail: { type: "string" },
    seniorSiteLeaderEmail: { type: "string" },
    siteLeaderEmail: { type: "string" },
    siteNumber: { type: "number" },
  },
  required: ["address", "lat", "lng", "mobileZoom", "webZoom", "siteNumber"],
  additionalProperties: false,
};

const updateSiteSchema = {
  type: "object",
  properties: {
    address: { type: "string" },
    lat: { type: "number" },
    lng: { type: "number" },
    mobileZoom: { type: "number" },
    webZoom: { type: "number" },
    officePhoneNumber: { type: "string" },
    regionalManagerEmail: { type: "string" },
    seniorSiteLeaderEmail: { type: "string" },
    siteLeaderEmail: { type: "string" },
    siteNumber: { type: "number" },
    deleteAttributes: {
      type: "array",
      items: {
        type: "string",
      },
    },
  },
  required: [],
  additionalProperties: false,
};

const userSchema = {
  type: "object",
  properties: {
    email: { type: "string" },
    tempPassword: { type: "string" },
    hasResetPassword: { type: "boolean" },
    name: { type: "string" },
    title: { type: "string" },
    isAdmin: { type: "boolean" },
    siteNumbers: {
      type: "array",
      items: { type: "number" },
    },
  },
  required: ["email", "tempPassword", "name", "title", "siteNumbers"],
  additionalProperties: false,
};

export function isValidUserData(userData) {
  return ajv.validate(userSchema, userData);
}

export function isValidSiteData(siteData, { isNewSite = true }) {
  return ajv.validate(isNewSite ? newSiteSchema : updateSiteSchema, siteData);
}
