import { UnitTypes } from "../enums/UnitTypes";
export const DerailStatus = {
  DERAIL_ON: "derail on",
  DISCONNECTED: "disconnected",
  DERAIL_OFF: "derail off",
};

export function unitTypeToStatuses(unitType) {
  switch (unitType) {
    case UnitTypes.DERAIL:
      return DerailStatus;
    default:
      return "";
  }
}
